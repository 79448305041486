small {
  font-size: inherit; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

.title {
  color: $c-title;
  line-height: 1;
  @include arci;
  font-weight: 400;
  @include css-lock(21, 36, 320, 1399, font-size);
  &--sm {
    @include css-lock(18, 30, 320, 1399, font-size); }
  &--h2 {
    @include css-lock(18, 24, 320, 1399, font-size);
    font-weight: 600;
    @include reg; }
  &--h3 {
    @include css-lock(16, 21, 320, 1399, font-size);
    font-weight: 600;
    @include reg; }
  &--h4 {
    @include css-lock(14, 18, 320, 1399, font-size);
    font-weight: 600;
    @include reg; }
  &--h5 {
    @include reg;
    font-weight: 700;
    font-size: 1em !important; }
  &--h6 {
    @include reg;
    font-weight: 700;
    font-size: $fz-sm !important; }
  &--reg {
    @include reg; }
  &--arci {
    @include arci; } }

.text {
  &--sm {
 }    // font-size: $fz-sm
  &--light {
    font-weight: 300; } }

blockquote {
  background-color: $gray-lightest;
  border-radius: $radius;
  padding: 35px;
  position: relative;
  overflow: hidden;
  @include before {
    background-color: $accent;
    width: 5px;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 1; }

  @include lg {
    padding: 25px; }
  @include md {
    padding: 15px; } }
