.steps {
  @extend .row;
  @include xs {
    margin-left: -5px;
    margin-right: -5px; }
  &__item {
    @extend .col-md-3;
    @extend .col-6;
    @include xs {
      padding-left: 5px;
      padding-right: 5px; }
    @include after {
      top: 72px;
      transform: translate(50%, 0);
      width: 65px;
      right: 0;
      position: absolute;
      border-top: 1px dashed $gray;
      @include md {
        top: 55px; }
      @include xs {
        width: 50px;
        top: 45px; } }

    &:nth-child(4n-4) {
      @include after {
        display: none; } }
    &:nth-child(2n-2) {
      @include sm {
        @include after {
          display: none; } } } } }


.step {
  text-align: center;
  max-width: 245px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  @include sm {
    margin-bottom: 15px; }
  &__nmb {
    text-align: left;
    color: $accent;
    font-weight: 700;
    margin-bottom: -10px; }
  &__icon {
    @include flex(center, center);
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    border: 1px solid  $gray-light;
    color: $white;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
    @include md {
      margin-bottom: 10px;
      width: 90px;
      height: 90px; }
    @include xs {
      width: 70px;
      height: 70px; }
    @include before {
      @include vcenter;
      z-index: 0;
      background-color: $accent;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      border-radius: 50%; }
    .icon {
      font-size: em(45);
      position: relative;
      z-index: 1; } }
  &__title {
    margin-bottom: 10px; } }
