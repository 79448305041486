@include font('gilroy', 'Gilroy-Light', 300);
@include font('gilroy', 'Gilroy-Regular', 400);
@include font('gilroy', 'Gilroy-Semibold', 600);
@include font('gilroy', 'Gilroy-Bold', 700);

// +font('gilroy', 'Gilroy-LightItalic', 300, italic)
@include font('gilroy', 'Gilroy-RegularItalic', 400, italic);
// +font('gilroy', 'Gilroy-BoldItalic', 700, italic)

@include font('arciform', 'arciform_sans_cyr-lat_regular-webfont');

@mixin arci {
  font-family: 'arciform', sans-serif; }

@mixin reg {
  font-family: 'gilroy', Arial, sans-serif; }
