.section {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  @include xl {
    padding-top: 50px;
    padding-bottom: 50px; }
  @include lg {
    padding-top: 40px;
    padding-bottom: 40px; }
  @include md {
    padding-top: 30px;
    padding-bottom: 30px; }
  &__title, &__nav {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } }
  &__nav {
    position: sticky;
    top: $header-bottom-height;
    z-index: 9;
    @include lg {
      top: $header-top-height-lg; }
    @include xs {
      position: static; } }
  &__bg {
    background-color: $gray;
    @include coverdiv;
    z-index: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } }
  &__inner {
    position: relative;
    z-index: 1; }
  &__block {
    @include notlast {
      margin-bottom: 30px;
      @include sm {
        margin-bottom: 15px; } } }
  &__contacts {
    height: 100%;
    @include flex(flex-start, normal, column);
    @include md {
      height: auto;
      margin-bottom: 30px; }
    @include sm {
      margin-bottom: 15px; }
    .map {
      flex-grow: 1; } }
  &__breadcrumbs {
    @extend .hero__breadcrumbs; }

  &--gray {
    background-color: $gray-lightest; }
  &--sm {
    padding-top: 30px;
    padding-bottom: 30px;
    @include xl {
      padding-top: 30px;
      padding-bottom: 30px; }
    @include lg {
      padding-top: 30px;
      padding-bottom: 30px; }
    @include md {
      padding-top: 30px;
      padding-bottom: 30px; } } }

.s-contacts {
  @include xs {
    padding-top: 0; }
  .section__bg {
    @include xs {
      position: relative;
      width: 100%;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      padding-top: 70%;
      margin-bottom: 15px; } } }

