.card {
  $this: &;
  @include flex(flex-start, normal, column);
  overflow: hidden;
  border-radius: $radius;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 1px solid transparent;
  height: calc(100% - 30px);
  margin-bottom: 30px;
  @include tr(border-color .5s, height .5s, box-shadow .3s);
  @include sm {
    margin-bottom: 15px;
    height: calc(100% - 15px); }
  @include xs {
    border-radius: $radius-sm; }
  @include hover {
    &:not(.card--shadow-mod) {
      border-color: $accent; }
    &:not(.card--lg) {
      #{$this}__btn {
        max-height: 100px; } } }
  &__content {
    flex-grow: 1;
    background-color: $white;
    padding: 25px;
    height: 220px;
    @include flex(center, normal, column);
    @include lg {
      padding: 20px; }
    @include md {
      padding: 15px; }
    @include xs {
      height: auto; } }
  &__img {
    display: block;
    @include cover;
    padding-top: 60%;
    position: relative;
    @include xs {
      padding-top: 90%; }
    img {
      @include vcenter;
      max-height: 100%;
      z-index: 1; } }
  &__title {
    margin-bottom: 10px;
    display: block; }
  &__text {
    margin-bottom: 15px;
    max-height: 3em;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @include md {
      margin-bottom: 10px; }
    @include xs {
      // overflow: visible
      // max-height: none
 } }      // display: block

  &__btn {
    max-height: 0;
    overflow: hidden;
    @include tr(max-height .5s); }
  &__date {
    margin-bottom: 15px;
    display: block;
    @include md {
      margin-bottom: 10px; } }

  &--shadow-mod {
    box-shadow: 0.551px 1.923px 6px 0px rgba(0, 0, 0, 0.09);
    @include hover {
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15); } }

  &--lg {
    text-align: left;
    #{$this}__btn {
      max-height: none;
      overflow: visible; }
    #{$this}__content {
      justify-content: flex-start; }
    #{$this}__text {
      flex-grow: 1; } } }
