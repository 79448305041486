.btn {
  $this: &;
  display: inline-block;
  text-align: center;
  color: $white;
  min-width: 250px;
  font-weight: 700;
  padding: 0.85em 1em;
  border-radius: $radius;
  background-color: $accent;
  border: 1px solid $accent;
  @include tr(background-color .3s, border-color .3s, color .3s, opacity .3s);
  @include hover {
    &:not(#{$this}--no-hover) {
      opacity: .7; } }
  @include xs {
    min-width: 0;
    width: 100%; }

  &--full {
    min-width: 0;
    width: 100%; } }
