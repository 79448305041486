.hero {
  $this: &;
  position: relative;
  background-color: $gray-lightest;
  @include cover;
  background-position: 70% 50%;
  padding-top: 30px;
  padding-bottom: 50px;
  &__breadcrumbs {
    margin-bottom: 15px; }
  &__bg {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    @include cover;
    @include lg {
      // background-position: 0 50%
      @include before {
        @include coverdiv;
        z-index: 0;
        background-color: rgba($white, .7); } }
    @include md {
      background-position: 60% 50%; }
    @include sm {
      background-position: 70% 50%;
 }      // display: none
    @include xs {
      left: 0;
      right: 0;
      width: auto;
      height: 50%;
      display: none; } }
  &__bg-left {
    left: 0; }
  &__bg-right {
    right: 0;
    @include xll {
      background-position: 0 50%; }
    @include xs {
      top: auto;
      bottom: 0; } }
  &__inner {
    position: relative;
    z-index: 1; }
  &__content {
    max-width: 295px;
    @include sm {
      max-width: none; } }
  &__right {
    padding-left: 50px;
    @include lg {
      padding-left: 0; } }
  &__label {
    position: absolute;
    z-index: 2;
    top: 25px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 160px;
    @include lg {
      top: 15px; }
    @include sm {
      position: static;
      transform: translate(0, 0);
      width: auto; } }
  &__img {
    display: none;
    max-width: 120px;
    margin-right: 15px;
    float: left;
    @include xs {
      display: inline-block; } }

  &--home {
    padding-top: 0;
    padding-bottom: 0;
    #{$this}__inner {
      @include lg {
        padding-top: 50px; }
      @include sm {
        padding-top: 50px;
        padding-bottom: 50px; } }
    #{$this}__left, #{$this}__right {
      padding-top: 25%;
      padding-bottom: 25%;
      @include sm {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 30px; } }
    // #{$this}__left
    //   +sm
 } }    //     margin-bottom: 30px

.hero-label {
  color: $accent;
  text-align: center;
  @include xs {
    @include flex(flex-start, center); }
  &__icon {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: $white;
    @include flex(center, center);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    @include xs {
      margin-left: 0;
      margin-right: 1em;
      margin-bottom: 0; }
    .icon {
      font-size: em(25);
      position: relative;
      z-index: 1; }
    @include before {
      background-color: #e8e8e8;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      z-index: 0; } }


  &__title {
    font-weight: 700;
    font-size: $fz-sm; } }
