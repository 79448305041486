.overflow-hidden {
  overflow: hidden; }

.text-center {
  text-align: center; }

.pointer-events-none {
  pointer-events: none; }

.pt-0 {
  padding-top: 0 !important; }
.pb-0 {
  padding-bottom: 0 !important; }

.mb-md {
  @include md {
    margin-bottom: 30px; }
  @include sm {
    margin-bottom: 15px; } }
