html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; vertical-align: baseline; }

html { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }

img, fieldset, a img { border: none; }

input[type="text"], input[type="email"], input[type="tel"], textarea { -webkit-appearance: none; }

input[type="submit"], button { cursor: pointer; }

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner { padding: 0; border: 0; }

textarea { overflow: auto; }

input, button { margin: 0; padding: 0; border: 0; }

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus { outline: none; }

ul, ol { list-style-type: none; }

table { border-spacing: 0; border-collapse: collapse; width: 100%; }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

@media (prefers-reduced-motion: reduce) { * { transition: none !important; } }

* { -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a { text-decoration: none; color: inherit; }

input, textarea, button { font-family: inherit; font-size: inherit; font-weight: inherit; font-style: inherit; color: inherit; line-height: inherit; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder { font-family: inherit; font-size: inherit; font-weight: inherit; font-style: inherit; }

input::placeholder, textarea::placeholder { font-family: inherit; font-size: inherit; font-weight: inherit; font-style: inherit; }

textarea { resize: none; border: 0; }

button, input[type="submit"] { background-color: transparent; overflow: visible; }

img { max-width: 100%; }

main { display: block; }

/*! Bootstrap Grid v4.3.1 (https://getbootstrap.com/) Copyright 2011-2019 The Bootstrap Authors Copyright 2011-2019 Twitter, Inc. Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE) */
html { box-sizing: border-box; -ms-overflow-style: scrollbar; }

*, *::before, *::after { box-sizing: inherit; }

.row, .steps, .contacts { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }

.no-gutters { margin-right: 0; margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .steps__item, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto { position: relative; width: 100%; padding-right: 15px; padding-left: 15px; }

.col { flex-basis: 0; flex-grow: 1; max-width: 100%; }

.col-auto { flex: 0 0 auto; width: auto; max-width: 100%; }

.col-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }

.col-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }

.col-3 { flex: 0 0 25%; max-width: 25%; }

.col-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }

.col-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }

.col-6, .steps__item { flex: 0 0 50%; max-width: 50%; }

.col-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }

.col-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }

.col-9 { flex: 0 0 75%; max-width: 75%; }

.col-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }

.col-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }

.col-12 { flex: 0 0 100%; max-width: 100%; }

.order-first { order: -1; }

.order-last { order: 13; }

.order-0 { order: 0; }

.order-1 { order: 1; }

.order-2 { order: 2; }

.order-3 { order: 3; }

.order-4 { order: 4; }

.order-5 { order: 5; }

.order-6 { order: 6; }

.order-7 { order: 7; }

.order-8 { order: 8; }

.order-9 { order: 9; }

.order-10 { order: 10; }

.order-11 { order: 11; }

.order-12 { order: 12; }

.offset-1 { margin-left: 8.33333%; }

.offset-2 { margin-left: 16.66667%; }

.offset-3 { margin-left: 25%; }

.offset-4 { margin-left: 33.33333%; }

.offset-5 { margin-left: 41.66667%; }

.offset-6 { margin-left: 50%; }

.offset-7 { margin-left: 58.33333%; }

.offset-8 { margin-left: 66.66667%; }

.offset-9 { margin-left: 75%; }

.offset-10 { margin-left: 83.33333%; }

.offset-11 { margin-left: 91.66667%; }

@media (min-width: 576px) { .col-sm { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-sm-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-sm-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-sm-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-sm-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-sm-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-sm-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
  .order-sm-first { order: -1; }
  .order-sm-last { order: 13; }
  .order-sm-0 { order: 0; }
  .order-sm-1 { order: 1; }
  .order-sm-2 { order: 2; }
  .order-sm-3 { order: 3; }
  .order-sm-4 { order: 4; }
  .order-sm-5 { order: 5; }
  .order-sm-6 { order: 6; }
  .order-sm-7 { order: 7; }
  .order-sm-8 { order: 8; }
  .order-sm-9 { order: 9; }
  .order-sm-10 { order: 10; }
  .order-sm-11 { order: 11; }
  .order-sm-12 { order: 12; }
  .offset-sm-0 { margin-left: 0; }
  .offset-sm-1 { margin-left: 8.33333%; }
  .offset-sm-2 { margin-left: 16.66667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.33333%; }
  .offset-sm-5 { margin-left: 41.66667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.33333%; }
  .offset-sm-8 { margin-left: 66.66667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.33333%; }
  .offset-sm-11 { margin-left: 91.66667%; } }

@media (min-width: 768px) { .col-md { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-md-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-md-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-md-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-md-3, .steps__item { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-md-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-md-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-md-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
  .order-md-first { order: -1; }
  .order-md-last { order: 13; }
  .order-md-0 { order: 0; }
  .order-md-1 { order: 1; }
  .order-md-2 { order: 2; }
  .order-md-3 { order: 3; }
  .order-md-4 { order: 4; }
  .order-md-5 { order: 5; }
  .order-md-6 { order: 6; }
  .order-md-7 { order: 7; }
  .order-md-8 { order: 8; }
  .order-md-9 { order: 9; }
  .order-md-10 { order: 10; }
  .order-md-11 { order: 11; }
  .order-md-12 { order: 12; }
  .offset-md-0 { margin-left: 0; }
  .offset-md-1 { margin-left: 8.33333%; }
  .offset-md-2 { margin-left: 16.66667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.33333%; }
  .offset-md-5 { margin-left: 41.66667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.33333%; }
  .offset-md-8 { margin-left: 66.66667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.33333%; }
  .offset-md-11 { margin-left: 91.66667%; } }

@media (min-width: 992px) { .col-lg { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-lg-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-lg-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-lg-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-lg-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-lg-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-lg-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
  .order-lg-first { order: -1; }
  .order-lg-last { order: 13; }
  .order-lg-0 { order: 0; }
  .order-lg-1 { order: 1; }
  .order-lg-2 { order: 2; }
  .order-lg-3 { order: 3; }
  .order-lg-4 { order: 4; }
  .order-lg-5 { order: 5; }
  .order-lg-6 { order: 6; }
  .order-lg-7 { order: 7; }
  .order-lg-8 { order: 8; }
  .order-lg-9 { order: 9; }
  .order-lg-10 { order: 10; }
  .order-lg-11 { order: 11; }
  .order-lg-12 { order: 12; }
  .offset-lg-0 { margin-left: 0; }
  .offset-lg-1 { margin-left: 8.33333%; }
  .offset-lg-2 { margin-left: 16.66667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.33333%; }
  .offset-lg-5 { margin-left: 41.66667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.33333%; }
  .offset-lg-8 { margin-left: 66.66667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.33333%; }
  .offset-lg-11 { margin-left: 91.66667%; } }

@media (min-width: 1200px) { .col-xl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-xl-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-xl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-xl-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-xl-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-xl-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-xl-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
  .order-xl-first { order: -1; }
  .order-xl-last { order: 13; }
  .order-xl-0 { order: 0; }
  .order-xl-1 { order: 1; }
  .order-xl-2 { order: 2; }
  .order-xl-3 { order: 3; }
  .order-xl-4 { order: 4; }
  .order-xl-5 { order: 5; }
  .order-xl-6 { order: 6; }
  .order-xl-7 { order: 7; }
  .order-xl-8 { order: 8; }
  .order-xl-9 { order: 9; }
  .order-xl-10 { order: 10; }
  .order-xl-11 { order: 11; }
  .order-xl-12 { order: 12; }
  .offset-xl-0 { margin-left: 0; }
  .offset-xl-1 { margin-left: 8.33333%; }
  .offset-xl-2 { margin-left: 16.66667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.33333%; }
  .offset-xl-5 { margin-left: 41.66667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.33333%; }
  .offset-xl-8 { margin-left: 66.66667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.33333%; }
  .offset-xl-11 { margin-left: 91.66667%; } }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }

.flex-nowrap { flex-wrap: nowrap !important; }

.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

.flex-fill { flex: 1 1 auto !important; }

.flex-grow-0 { flex-grow: 0 !important; }

.flex-grow-1 { flex-grow: 1 !important; }

.flex-shrink-0 { flex-shrink: 0 !important; }

.flex-shrink-1 { flex-shrink: 1 !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-sm-fill { flex: 1 1 auto !important; }
  .flex-sm-grow-0 { flex-grow: 0 !important; }
  .flex-sm-grow-1 { flex-grow: 1 !important; }
  .flex-sm-shrink-0 { flex-shrink: 0 !important; }
  .flex-sm-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; } }

@media (min-width: 768px) { .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-md-fill { flex: 1 1 auto !important; }
  .flex-md-grow-0 { flex-grow: 0 !important; }
  .flex-md-grow-1 { flex-grow: 1 !important; }
  .flex-md-shrink-0 { flex-shrink: 0 !important; }
  .flex-md-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; } }

@media (min-width: 992px) { .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-lg-fill { flex: 1 1 auto !important; }
  .flex-lg-grow-0 { flex-grow: 0 !important; }
  .flex-lg-grow-1 { flex-grow: 1 !important; }
  .flex-lg-shrink-0 { flex-shrink: 0 !important; }
  .flex-lg-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; } }

@media (min-width: 1200px) { .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-xl-fill { flex: 1 1 auto !important; }
  .flex-xl-grow-0 { flex-grow: 0 !important; }
  .flex-xl-grow-1 { flex-grow: 1 !important; }
  .flex-xl-shrink-0 { flex-shrink: 0 !important; }
  .flex-xl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; } }

.tns-outer { padding: 0 !important; }

.tns-outer [hidden] { display: none !important; }

.tns-outer [aria-controls], .tns-outer [data-action] { cursor: pointer; }

.tns-slider { -webkit-transition: all 0s; -moz-transition: all 0s; transition: all 0s; }

.tns-slider > .tns-item { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.tns-horizontal.tns-subpixel { white-space: nowrap; }

.tns-horizontal.tns-subpixel > .tns-item { display: inline-block; vertical-align: top; white-space: normal; }

.tns-horizontal.tns-no-subpixel:after { content: ''; display: table; clear: both; }

.tns-horizontal.tns-no-subpixel > .tns-item { float: left; }

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item { margin-right: -100%; }

.tns-no-calc { position: relative; left: 0; }

.tns-gallery { position: relative; left: 0; min-height: 1px; }

.tns-gallery > .tns-item { position: absolute; left: -100%; -webkit-transition: transform 0s, opacity 0s; -moz-transition: transform 0s, opacity 0s; transition: transform 0s, opacity 0s; }

.tns-gallery > .tns-slide-active { position: relative; left: auto !important; }

.tns-gallery > .tns-moving { -webkit-transition: all 0.25s; -moz-transition: all 0.25s; transition: all 0.25s; }

.tns-autowidth { display: inline-block; }

.tns-lazy-img { -webkit-transition: opacity 0.6s; -moz-transition: opacity 0.6s; transition: opacity 0.6s; opacity: 0.6; }

.tns-lazy-img.tns-complete { opacity: 1; }

.tns-ah { -webkit-transition: height 0s; -moz-transition: height 0s; transition: height 0s; }

.tns-ovh { overflow: hidden; }

.tns-visually-hidden { position: absolute; left: -10000em; }

.tns-transparent { opacity: 0; visibility: hidden; }

.tns-fadeIn { opacity: 1; filter: alpha(opacity=100); z-index: 0; }

.tns-normal, .tns-fadeOut { opacity: 0; filter: alpha(opacity=0); z-index: -1; }

.tns-vpfix { white-space: nowrap; }

.tns-vpfix > div, .tns-vpfix > li { display: inline-block; }

.tns-t-subp2 { margin: 0 auto; width: 310px; position: relative; height: 10px; overflow: hidden; }

.tns-t-ct { width: 2333.3333333%; width: -webkit-calc(100% * 70 / 3); width: -moz-calc(100% * 70 / 3); width: calc(100% * 70 / 3); position: absolute; right: 0; }

.tns-t-ct:after { content: ''; display: table; clear: both; }

.tns-t-ct > div { width: 1.4285714%; width: -webkit-calc(100% / 70); width: -moz-calc(100% / 70); width: calc(100% / 70); height: 10px; float: left; }

/*# sourceMappingURL=sourcemaps/tiny-slider.css.map */
@font-face { font-family: 'lg'; src: url("../fonts/lg.eot?n1z373"); src: url("../fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../fonts/lg.woff?n1z373") format("woff"), url("../fonts/lg.ttf?n1z373") format("truetype"), url("../fonts/lg.svg?n1z373#lg") format("svg"); font-weight: normal; font-style: normal; }

.lg-icon { font-family: 'lg'; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev { background-color: rgba(0, 0, 0, 0.45); border-radius: 2px; color: #999; cursor: pointer; display: block; font-size: 22px; margin-top: -10px; padding: 8px 10px 9px; position: absolute; top: 50%; z-index: 1080; }

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled { pointer-events: none; opacity: 0.5; }

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover { color: #FFF; }

.lg-actions .lg-next { right: 20px; }

.lg-actions .lg-next:before { content: "\e095"; }

.lg-actions .lg-prev { left: 20px; }

.lg-actions .lg-prev:after { content: "\e094"; }

@-webkit-keyframes lg-right-end { 0% { left: 0; }
  50% { left: -30px; }
  100% { left: 0; } }

@-moz-keyframes lg-right-end { 0% { left: 0; }
  50% { left: -30px; }
  100% { left: 0; } }

@-ms-keyframes lg-right-end { 0% { left: 0; }
  50% { left: -30px; }
  100% { left: 0; } }

@keyframes lg-right-end { 0% { left: 0; }
  50% { left: -30px; }
  100% { left: 0; } }

@-webkit-keyframes lg-left-end { 0% { left: 0; }
  50% { left: 30px; }
  100% { left: 0; } }

@-moz-keyframes lg-left-end { 0% { left: 0; }
  50% { left: 30px; }
  100% { left: 0; } }

@-ms-keyframes lg-left-end { 0% { left: 0; }
  50% { left: 30px; }
  100% { left: 0; } }

@keyframes lg-left-end { 0% { left: 0; }
  50% { left: 30px; }
  100% { left: 0; } }

.lg-outer.lg-right-end .lg-object { -webkit-animation: lg-right-end 0.3s; -o-animation: lg-right-end 0.3s; animation: lg-right-end 0.3s; position: relative; }

.lg-outer.lg-left-end .lg-object { -webkit-animation: lg-left-end 0.3s; -o-animation: lg-left-end 0.3s; animation: lg-left-end 0.3s; position: relative; }

.lg-toolbar { z-index: 1082; left: 0; position: absolute; top: 0; width: 100%; background-color: rgba(0, 0, 0, 0.45); }

.lg-toolbar .lg-icon { color: #999; cursor: pointer; float: right; font-size: 24px; height: 47px; line-height: 27px; padding: 10px 0; text-align: center; width: 50px; text-decoration: none !important; outline: medium none; -webkit-transition: color 0.2s linear; -o-transition: color 0.2s linear; transition: color 0.2s linear; }

.lg-toolbar .lg-icon:hover { color: #FFF; }

.lg-toolbar .lg-close:after { content: "\e070"; }

.lg-toolbar .lg-download:after { content: "\e0f2"; }

.lg-sub-html { background-color: rgba(0, 0, 0, 0.45); bottom: 0; color: #EEE; font-size: 16px; left: 0; padding: 10px 40px; position: fixed; right: 0; text-align: center; z-index: 1080; }

.lg-sub-html h4 { margin: 0; font-size: 13px; font-weight: bold; }

.lg-sub-html p { font-size: 12px; margin: 5px 0 0; }

#lg-counter { color: #999; display: inline-block; font-size: 16px; padding-left: 20px; padding-top: 12px; vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next { opacity: 1; -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev { opacity: 0; -webkit-transform: translate3d(-10px, 0, 0); transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next { opacity: 0; -webkit-transform: translate3d(10px, 0, 0); transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar { opacity: 0; -webkit-transform: translate3d(0, -10px, 0); transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object { -webkit-transform: scale3d(0.5, 0.5, 0.5); transform: scale3d(0.5, 0.5, 0.5); opacity: 0; -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important; -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important; -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important; transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important; -webkit-transform-origin: 50% 50%; -moz-transform-origin: 50% 50%; -ms-transform-origin: 50% 50%; transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); opacity: 1; }

.lg-outer .lg-thumb-outer { background-color: #0D0A0A; bottom: 0; position: absolute; width: 100%; z-index: 1080; max-height: 350px; -webkit-transform: translate3d(0, 100%, 0); transform: translate3d(0, 100%, 0); -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item { cursor: -webkit-grab; cursor: -moz-grab; cursor: -o-grab; cursor: -ms-grab; cursor: grab; }

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item { cursor: move; cursor: -webkit-grabbing; cursor: -moz-grabbing; cursor: -o-grabbing; cursor: -ms-grabbing; cursor: grabbing; }

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb { -webkit-transition-duration: 0s !important; transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer { -webkit-transform: translate3d(0, 0%, 0); transform: translate3d(0, 0%, 0); }

.lg-outer .lg-thumb { padding: 10px 0; height: 100%; margin-bottom: -5px; }

.lg-outer .lg-thumb-item { border-radius: 5px; cursor: pointer; float: left; overflow: hidden; height: 100%; border: 2px solid #FFF; border-radius: 4px; margin-bottom: 5px; }

@media (min-width: 1025px) { .lg-outer .lg-thumb-item { -webkit-transition: border-color 0.25s ease; -o-transition: border-color 0.25s ease; transition: border-color 0.25s ease; } }

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover { border-color: #a90707; }

.lg-outer .lg-thumb-item img { width: 100%; height: 100%; object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item { padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item { padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html { -webkit-transition: bottom 0.25s ease; -o-transition: bottom 0.25s ease; transition: bottom 0.25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html { bottom: 100px; }

.lg-outer .lg-toggle-thumb { background-color: #0D0A0A; border-radius: 2px 2px 0 0; color: #999; cursor: pointer; font-size: 24px; height: 39px; line-height: 27px; padding: 5px 0; position: absolute; right: 20px; text-align: center; top: -39px; width: 50px; }

.lg-outer .lg-toggle-thumb:after { content: "\e1ff"; }

.lg-outer .lg-toggle-thumb:hover { color: #FFF; }

.lg-outer .lg-video-cont { display: inline-block; vertical-align: middle; max-width: 1140px; max-height: 100%; width: 100%; padding: 0 5px; }

.lg-outer .lg-video { width: 100%; height: 0; padding-bottom: 56.25%; overflow: hidden; position: relative; }

.lg-outer .lg-video .lg-object { display: inline-block; position: absolute; top: 0; left: 0; width: 100% !important; height: 100% !important; }

.lg-outer .lg-video .lg-video-play { width: 84px; height: 59px; position: absolute; left: 50%; top: 50%; margin-left: -42px; margin-top: -30px; z-index: 1080; cursor: pointer; }

.lg-outer .lg-has-vimeo .lg-video-play { background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play { background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play { background: transparent url("../img/video-play.png") no-repeat scroll 0 0; height: 64px; margin-left: -32px; margin-top: -32px; width: 64px; opacity: 0.8; }

.lg-outer .lg-has-html5:hover .lg-video-play { opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play { background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play { background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-video-object { width: 100% !important; height: 100% !important; position: absolute; top: 0; left: 0; }

.lg-outer .lg-has-video .lg-video-object { visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play { display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object { visibility: visible; }

.lg-progress-bar { background-color: #333; height: 5px; left: 0; position: absolute; top: 0; width: 100%; z-index: 1083; opacity: 0; -webkit-transition: opacity 0.08s ease 0s; -moz-transition: opacity 0.08s ease 0s; -o-transition: opacity 0.08s ease 0s; transition: opacity 0.08s ease 0s; }

.lg-progress-bar .lg-progress { background-color: #a90707; height: 5px; width: 0; }

.lg-progress-bar.lg-start .lg-progress { width: 100%; }

.lg-show-autoplay .lg-progress-bar { opacity: 1; }

.lg-autoplay-button:after { content: "\e01d"; }

.lg-show-autoplay .lg-autoplay-button:after { content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image { -webkit-transition-duration: 0s; transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap { -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap { -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important; -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important; -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important; transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important; -webkit-transform-origin: 0 0; -moz-transform-origin: 0 0; -ms-transform-origin: 0 0; transform-origin: 0 0; -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; backface-visibility: hidden; }

#lg-zoom-in:after { content: "\e311"; }

#lg-actual-size { font-size: 20px; }

#lg-actual-size:after { content: "\e033"; }

#lg-zoom-out { opacity: 0.5; pointer-events: none; }

#lg-zoom-out:after { content: "\e312"; }

.lg-zoomed #lg-zoom-out { opacity: 1; pointer-events: auto; }

.lg-outer .lg-pager-outer { bottom: 60px; left: 0; position: absolute; right: 0; text-align: center; z-index: 1080; height: 10px; }

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont { overflow: visible; }

.lg-outer .lg-pager-cont { cursor: pointer; display: inline-block; overflow: hidden; position: relative; vertical-align: top; margin: 0 5px; }

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont { opacity: 1; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager { box-shadow: 0 0 0 2px white inset; }

.lg-outer .lg-pager-thumb-cont { background-color: #fff; color: #FFF; bottom: 100%; height: 83px; left: 0; margin-bottom: 20px; margin-left: -60px; opacity: 0; padding: 5px; position: absolute; width: 120px; border-radius: 3px; -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s; -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s; -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s; transition: opacity 0.15s ease 0s, transform 0.15s ease 0s; -webkit-transform: translate3d(0, 5px, 0); transform: translate3d(0, 5px, 0); }

.lg-outer .lg-pager-thumb-cont img { width: 100%; height: 100%; }

.lg-outer .lg-pager { background-color: rgba(255, 255, 255, 0.5); border-radius: 50%; box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset; display: block; height: 12px; -webkit-transition: box-shadow 0.3s ease 0s; -o-transition: box-shadow 0.3s ease 0s; transition: box-shadow 0.3s ease 0s; width: 12px; }

.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus { box-shadow: 0 0 0 8px white inset; }

.lg-outer .lg-caret { border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px dashed; bottom: -10px; display: inline-block; height: 0; left: 50%; margin-left: -5px; position: absolute; vertical-align: middle; width: 0; }

.lg-fullscreen:after { content: "\e20c"; }

.lg-fullscreen-on .lg-fullscreen:after { content: "\e20d"; }

.lg-outer #lg-dropdown-overlay { background-color: rgba(0, 0, 0, 0.25); bottom: 0; cursor: default; left: 0; position: fixed; right: 0; top: 0; z-index: 1081; opacity: 0; visibility: hidden; -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; }

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay { -webkit-transition-delay: 0s; transition-delay: 0s; -moz-transform: translate3d(0, 0px, 0); -o-transform: translate3d(0, 0px, 0); -ms-transform: translate3d(0, 0px, 0); -webkit-transform: translate3d(0, 0px, 0); transform: translate3d(0, 0px, 0); opacity: 1; visibility: visible; }

.lg-outer.lg-dropdown-active #lg-share { color: #FFF; }

.lg-outer .lg-dropdown { background-color: #fff; border-radius: 2px; font-size: 14px; list-style-type: none; margin: 0; padding: 10px 0; position: absolute; right: 0; text-align: left; top: 50px; opacity: 0; visibility: hidden; -moz-transform: translate3d(0, 5px, 0); -o-transform: translate3d(0, 5px, 0); -ms-transform: translate3d(0, 5px, 0); -webkit-transform: translate3d(0, 5px, 0); transform: translate3d(0, 5px, 0); -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; }

.lg-outer .lg-dropdown:after { content: ""; display: block; height: 0; width: 0; position: absolute; border: 8px solid transparent; border-bottom-color: #FFF; right: 16px; top: -16px; }

.lg-outer .lg-dropdown > li:last-child { margin-bottom: 0px; }

.lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon { color: #333; }

.lg-outer .lg-dropdown a { color: #333; display: block; white-space: pre; padding: 4px 12px; font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif; font-size: 12px; }

.lg-outer .lg-dropdown a:hover { background-color: rgba(0, 0, 0, 0.07); }

.lg-outer .lg-dropdown .lg-dropdown-text { display: inline-block; line-height: 1; margin-top: -3px; vertical-align: middle; }

.lg-outer .lg-dropdown .lg-icon { color: #333; display: inline-block; float: none; font-size: 20px; height: auto; line-height: 1; margin-right: 8px; padding: 0; vertical-align: middle; width: auto; }

.lg-outer #lg-share { position: relative; }

.lg-outer #lg-share:after { content: "\e80d"; }

.lg-outer #lg-share-facebook .lg-icon { color: #3b5998; }

.lg-outer #lg-share-facebook .lg-icon:after { content: "\e901"; }

.lg-outer #lg-share-twitter .lg-icon { color: #00aced; }

.lg-outer #lg-share-twitter .lg-icon:after { content: "\e904"; }

.lg-outer #lg-share-googleplus .lg-icon { color: #dd4b39; }

.lg-outer #lg-share-googleplus .lg-icon:after { content: "\e902"; }

.lg-outer #lg-share-pinterest .lg-icon { color: #cb2027; }

.lg-outer #lg-share-pinterest .lg-icon:after { content: "\e903"; }

.group { *zoom: 1; }

.group:before, .group:after { display: table; content: ""; line-height: 0; }

.group:after { clear: both; }

.lg-outer { width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 1050; opacity: 0; -webkit-transition: opacity 0.15s ease 0s; -o-transition: opacity 0.15s ease 0s; transition: opacity 0.15s ease 0s; }

.lg-outer * { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.lg-outer.lg-visible { opacity: 1; }

.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current { -webkit-transition-duration: inherit !important; transition-duration: inherit !important; -webkit-transition-timing-function: inherit !important; transition-timing-function: inherit !important; }

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current { -webkit-transition-duration: 0s !important; transition-duration: 0s !important; opacity: 1; }

.lg-outer.lg-grab img.lg-object { cursor: -webkit-grab; cursor: -moz-grab; cursor: -o-grab; cursor: -ms-grab; cursor: grab; }

.lg-outer.lg-grabbing img.lg-object { cursor: move; cursor: -webkit-grabbing; cursor: -moz-grabbing; cursor: -o-grabbing; cursor: -ms-grabbing; cursor: grabbing; }

.lg-outer .lg { height: 100%; width: 100%; position: relative; overflow: hidden; margin-left: auto; margin-right: auto; max-width: 100%; max-height: 100%; }

.lg-outer .lg-inner { width: 100%; height: 100%; position: absolute; left: 0; top: 0; white-space: nowrap; }

.lg-outer .lg-item { background: url("../img/loading.gif") no-repeat scroll center center transparent; display: none !important; }

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide { display: inline-block !important; }

.lg-outer.lg-css .lg-current { display: inline-block !important; }

.lg-outer .lg-item, .lg-outer .lg-img-wrap { display: inline-block; text-align: center; position: absolute; width: 100%; height: 100%; }

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before { content: ""; display: inline-block; height: 50%; width: 1px; margin-right: -1px; }

.lg-outer .lg-img-wrap { position: absolute; padding: 0 5px; left: 0; right: 0; top: 0; bottom: 0; }

.lg-outer .lg-item.lg-complete { background-image: none; }

.lg-outer .lg-item.lg-current { z-index: 1060; }

.lg-outer .lg-image { display: inline-block; vertical-align: middle; max-width: 100%; max-height: 100%; width: auto !important; height: auto !important; }

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play { opacity: 0; -webkit-transition: opacity 0.15s ease 0s; -o-transition: opacity 0.15s ease 0s; transition: opacity 0.15s ease 0s; }

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play { opacity: 1; }

.lg-outer .lg-empty-html { display: none; }

.lg-outer.lg-hide-download #lg-download { display: none; }

.lg-backdrop { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 1040; background-color: #000; opacity: 0; -webkit-transition: opacity 0.15s ease 0s; -o-transition: opacity 0.15s ease 0s; transition: opacity 0.15s ease 0s; }

.lg-backdrop.in { opacity: 1; }

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current { -webkit-transition: none 0s ease 0s !important; -moz-transition: none 0s ease 0s !important; -o-transition: none 0s ease 0s !important; transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item { opacity: 0; }

.lg-css3.lg-fade .lg-item.lg-current { opacity: 1; }

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current { -webkit-transition: opacity 0.1s ease 0s; -moz-transition: opacity 0.1s ease 0s; -o-transition: opacity 0.1s ease 0s; transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item { opacity: 0; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide { -webkit-transform: translate3d(-100%, 0, 0); transform: translate3d(-100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide { -webkit-transform: translate3d(100%, 0, 0); transform: translate3d(100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); opacity: 1; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current { -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item { opacity: 0; position: absolute; left: 0; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide { left: -100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide { left: 100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current { left: 0; opacity: 1; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current { -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

[data-simplebar] { position: relative; flex-direction: column; flex-wrap: wrap; justify-content: flex-start; align-content: flex-start; align-items: flex-start; }

.simplebar-wrapper { overflow: hidden; width: inherit; height: inherit; max-width: inherit; max-height: inherit; }

.simplebar-mask { direction: inherit; position: absolute; overflow: hidden; padding: 0; margin: 0; left: 0; top: 0; bottom: 0; right: 0; width: auto !important; height: auto !important; z-index: 0; }

.simplebar-offset { direction: inherit !important; box-sizing: inherit !important; resize: none !important; position: absolute; top: 0; left: 0; bottom: 0; right: 0; padding: 0; margin: 0; -webkit-overflow-scrolling: touch; }

.simplebar-content-wrapper { direction: inherit; box-sizing: border-box !important; position: relative; display: block; height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */ width: auto; visibility: visible; max-width: 100%; /* Not required for horizontal scroll to trigger */ max-height: 100%; /* Needed for vertical scroll to trigger */ scrollbar-width: none; }

.simplebar-content-wrapper::-webkit-scrollbar, .simplebar-hide-scrollbar::-webkit-scrollbar { display: none; }

.simplebar-content:before, .simplebar-content:after { content: ' '; display: table; }

.simplebar-placeholder { max-height: 100%; max-width: 100%; width: 100%; pointer-events: none; }

.simplebar-height-auto-observer-wrapper { box-sizing: inherit !important; height: 100%; width: 100%; max-width: 1px; position: relative; float: left; max-height: 1px; overflow: hidden; z-index: -1; padding: 0; margin: 0; pointer-events: none; flex-grow: inherit; flex-shrink: 0; flex-basis: 0; }

.simplebar-height-auto-observer { box-sizing: inherit; display: block; opacity: 0; position: absolute; top: 0; left: 0; height: 1000%; width: 1000%; min-height: 1px; min-width: 1px; overflow: hidden; pointer-events: none; z-index: -1; }

.simplebar-track { z-index: 1; position: absolute; right: 0; bottom: 0; pointer-events: none; overflow: hidden; }

[data-simplebar].simplebar-dragging .simplebar-content { pointer-events: none; user-select: none; -webkit-user-select: none; }

[data-simplebar].simplebar-dragging .simplebar-track { pointer-events: all; }

.simplebar-scrollbar { position: absolute; right: 2px; width: 7px; min-height: 10px; }

.simplebar-scrollbar:before { position: absolute; content: ''; background: black; border-radius: 7px; left: 0; right: 0; opacity: 0; transition: opacity 0.2s linear; }

.simplebar-scrollbar.simplebar-visible:before { /* When hovered, remove all transitions from drag handle */ opacity: 0.5; transition: opacity 0s linear; }

.simplebar-track.simplebar-vertical { top: 0; width: 11px; }

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before { top: 2px; bottom: 2px; }

.simplebar-track.simplebar-horizontal { left: 0; height: 11px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before { height: 100%; left: 2px; right: 2px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar { right: auto; left: 0; top: 2px; height: 7px; min-height: 0; min-width: 10px; width: auto; }

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical { right: auto; left: 0; }

.hs-dummy-scrollbar-size { direction: rtl; position: fixed; opacity: 0; visibility: hidden; height: 500px; width: 500px; overflow-y: hidden; overflow-x: scroll; }

.simplebar-hide-scrollbar { position: fixed; left: 0; visibility: hidden; overflow-y: scroll; scrollbar-width: none; }

@font-face { font-family: "gilroy"; src: url("../fonts/Gilroy-Light.woff2") format("woff2"), url("../fonts/Gilroy-Light.woff") format("woff"); font-weight: 300; font-style: normal; font-display: swap; }

@font-face { font-family: "gilroy"; src: url("../fonts/Gilroy-Regular.woff2") format("woff2"), url("../fonts/Gilroy-Regular.woff") format("woff"); font-weight: 400; font-style: normal; font-display: swap; }

@font-face { font-family: "gilroy"; src: url("../fonts/Gilroy-Semibold.woff2") format("woff2"), url("../fonts/Gilroy-Semibold.woff") format("woff"); font-weight: 600; font-style: normal; font-display: swap; }

@font-face { font-family: "gilroy"; src: url("../fonts/Gilroy-Bold.woff2") format("woff2"), url("../fonts/Gilroy-Bold.woff") format("woff"); font-weight: 700; font-style: normal; font-display: swap; }

@font-face { font-family: "gilroy"; src: url("../fonts/Gilroy-RegularItalic.woff2") format("woff2"), url("../fonts/Gilroy-RegularItalic.woff") format("woff"); font-weight: 400; font-style: italic; font-display: swap; }

@font-face { font-family: "arciform"; src: url("../fonts/arciform_sans_cyr-lat_regular-webfont.woff2") format("woff2"), url("../fonts/arciform_sans_cyr-lat_regular-webfont.woff") format("woff"); font-weight: 400; font-style: normal; font-display: swap; }

small { font-size: inherit; }

h1, h2, h3, h4, h5, h6 { font-weight: 400; }

.title, .article h1:not(.title), .article h2:not(.title), .article h4:not(.title), .article h3:not(.title) { color: #353535; line-height: 1; font-family: 'arciform', sans-serif; font-weight: 400; font-size: 36px; font-size: calc(21px + (36 - 21) * ((100vw - 320px) / (1399 - 320))); }

@media (max-width: 320px) { .title, .article h1:not(.title), .article h2:not(.title), .article h4:not(.title), .article h3:not(.title) { font-size: 21px; } }

@media (min-width: 1399px) { .title, .article h1:not(.title), .article h2:not(.title), .article h4:not(.title), .article h3:not(.title) { font-size: 36px; } }

.title--sm { font-size: 30px; font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1399 - 320))); }

@media (max-width: 320px) { .title--sm { font-size: 18px; } }

@media (min-width: 1399px) { .title--sm { font-size: 30px; } }

.title--h2, .article h1:not(.title), .article h2:not(.title), .article h4:not(.title) { font-size: 24px; font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1399 - 320))); font-weight: 600; font-family: 'gilroy', Arial, sans-serif; }

@media (max-width: 320px) { .title--h2, .article h1:not(.title), .article h2:not(.title), .article h4:not(.title) { font-size: 18px; } }

@media (min-width: 1399px) { .title--h2, .article h1:not(.title), .article h2:not(.title), .article h4:not(.title) { font-size: 24px; } }

.title--h3, .article h3:not(.title) { font-size: 21px; font-size: calc(16px + (21 - 16) * ((100vw - 320px) / (1399 - 320))); font-weight: 600; font-family: 'gilroy', Arial, sans-serif; }

@media (max-width: 320px) { .title--h3, .article h3:not(.title) { font-size: 16px; } }

@media (min-width: 1399px) { .title--h3, .article h3:not(.title) { font-size: 21px; } }

.title--h4 { font-size: 18px; font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1399 - 320))); font-weight: 600; font-family: 'gilroy', Arial, sans-serif; }

@media (max-width: 320px) { .title--h4 { font-size: 14px; } }

@media (min-width: 1399px) { .title--h4 { font-size: 18px; } }

.title--h5 { font-family: 'gilroy', Arial, sans-serif; font-weight: 700; font-size: 1em !important; }

.title--h6 { font-family: 'gilroy', Arial, sans-serif; font-weight: 700; font-size: 0.875em !important; }

.title--reg { font-family: 'gilroy', Arial, sans-serif; }

.title--arci { font-family: 'arciform', sans-serif; }

.text--light { font-weight: 300; }

blockquote { background-color: #fafafa; border-radius: 25px; padding: 35px; position: relative; overflow: hidden; }

blockquote::before { content: ''; background-color: #8dc63f; width: 5px; height: 100%; top: 0; bottom: 0; left: 0; position: absolute; z-index: 1; }

@media (max-width: 1199px) { blockquote { padding: 25px; } }

@media (max-width: 991px) { blockquote { padding: 15px; } }

body { background: #fff; line-height: 1.4; font-size: 16px; min-width: 320px; font-family: 'gilroy', Arial, sans-serif; color: #353535; }

body.no-scroll { overflow: hidden; }

.no-touch body.no-scroll { margin-right: 17px; }

@media (max-width: 767px) { body { font-size: 14px; } }

@media (max-width: 1199px) { .out { padding-top: 55px; } }

.icon { display: inline-block; fill: currentColor; font-size: inherit; }

.lazy.is-loaded:not(.no-fade-in) { animation: fadeIn 1s; }

.container { width: 100%; padding-left: 15px; padding-right: 15px; margin-left: auto; margin-right: auto; max-width: 1440px; }

.logo { font-size: 0; display: inline-block; max-width: 185px; }

@media (max-width: 1199px) { .logo { max-width: 150px; } }

@media (max-width: 479px) { .logo { width: 80px; } }

.close { position: relative; display: inline-block; width: 20px; height: 20px; position: absolute; z-index: 1; top: 20px; right: 20px; transition: color 0.3s; }

.close::before, .close::after { position: absolute; content: ''; width: 2px; height: 100%; background-color: currentColor; top: 0; left: 50%; }

.close::before { transform: rotate(45deg); }

.close::after { transform: rotate(-45deg); }

.no-touch .close:hover { color: #8dc63f; }

.date { font-size: 0.75em; }

.link-arrow { display: inline-flex; justify-content: flex-start; align-items: center; font-weight: 700; color: #8dc63f; }

.no-touch .link-arrow:hover:not(.link-arrow--no-hover) .link-arrow__arr { transform: translate(10px, 0); }

.link-arrow__title { margin-right: 1.25em; display: block; }

.link-arrow__arr { width: 60px; position: relative; display: block; transition: width 0.5s, transform 0.5s; }

.link-arrow__arr::before { content: ''; width: 100%; background-color: currentColor; height: 1px; position: absolute; top: 50%; transform: translate(0, -50%); right: 0; }

.link-arrow__arr::after { content: ''; width: 10px; height: 10px; border-left: 1px solid currentColor; border-bottom: 1px solid currentColor; transform: rotate(225deg); position: absolute; top: 50%; transform: translate(0, -50%) rotate(225deg); right: 0; }

.list li { padding-left: 1em; position: relative; }

.list li::before { content: '\2022'; display: inline-block; }

.list li:not(:last-child) { margin-bottom: 1em; }

.list li::before { margin-right: .5em; color: #8dc63f; font-size: 2em; position: absolute; top: -.3em; left: 0; }

.list--left { margin-left: -1em; }

.gallery-thumb { padding-top: 7%; position: relative; overflow: hidden; }

.no-touch .gallery-thumb:hover .gallery-thumb__cover { visibility: visible; opacity: 1; }

.gallery-thumb__img, .gallery-thumb__cover { top: 0; bottom: 0; left: 0; right: 5px; position: absolute; display: block; border-radius: 5px; }

.gallery-thumb__img { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; z-index: 0; }

.gallery-thumb__cover { background-color: rgba(0, 0, 0, 0.5); display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; color: #8dc63f; transition: opacity 0.3s, visibility 0.3s; visibility: hidden; opacity: 0; z-index: 1; }

.gallery-thumb__cover .icon { font-size: 1.5em; }

.link { font-weight: 700; transition: color 0.3s, opacity 0.3s; color: #8dc63f; }

.no-touch .link:hover { text-decoration: underline; }

.fadeIn { animation-name: fadeIn; }

@keyframes fadeIn { 0% { opacity: 0; }
  100% { opacity: 1; } }

.header { position: relative; z-index: 10; }

.header .container { height: 100%; }

@media (max-width: 1199px) { .header { position: fixed; top: 0; left: 0; right: 0; } }

.header.is-fixed .header__bottom { position: fixed; left: 0; right: 0; top: 0; z-index: 10; }

.header__top { height: 70px; background-color: #fff; }

@media (max-width: 1199px) { .header__top { height: 55px; } }

.header__bottom { height: 75px; box-shadow: inset 1.696px -1.06px 19px 0px rgba(0, 0, 0, 0.1); background-color: #fff; color: #353535; }

@media (max-width: 1199px) { .header__bottom { height: 0; } }

.header__inner { height: 100%; display: flex; justify-content: flex-start; align-items: center; flex-direction: row; flex-wrap: nowrap; }

@media (max-width: 1199px) { .header__inner { padding-top: 10px; padding-bottom: 10px; } }

.header__logo { font-size: 0; }

.header__contacts { display: flex; justify-content: space-around; align-items: center; flex-direction: row; flex-wrap: nowrap; flex-grow: 1; padding-left: 15px; padding-right: 15px; }

@media (max-width: 1199px) { .header__contacts { justify-content: flex-end; } }

@media (max-width: 1199px) { .header__contact:not(.header__phone) { display: none; } }

.header__lang { text-align: right; }

@media (max-width: 479px) { .header__phone a { font-size: 0.75em; } }

.header__nav { width: 100%; }

@media (max-width: 1199px) { .header__nav { position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 10; padding: 15px; overflow: auto; height: auto; background-color: #fff; visibility: hidden; opacity: 0; text-align: center; font-size: 0; }
  .is-ready .header__nav { transition: opacity 0.3s, visibility 0.3s; }
  .header__nav:before { content: ""; display: inline-block; vertical-align: middle; width: 0; height: 100%; }
  .header__nav.is-active { visibility: visible; opacity: 1; } }

@media (max-width: 1199px) { .header__nav-in { display: inline-block; vertical-align: middle; font-size: 16px; } }

@media (max-width: 1199px) and (max-width: 767px) { .header__nav-in { font-size: 14px; } }

.header__burger { display: none; margin-left: 15px; }

@media (max-width: 1199px) { .header__burger { display: block; } }

@media (max-width: 575px) { .header__burger { margin-left: 5px; } }

.header__close { color: #353535; display: none; }

@media (max-width: 1199px) { .header__close { display: block; } }

.header-contact { display: flex; justify-content: flex-start; align-items: center; flex-direction: row; flex-wrap: nowrap; color: #353535; }

.header-contact__icon { margin-right: 8px; color: #8dc63f; font-size: 0; }

@media (max-width: 479px) { .header-contact__icon { margin-right: 4px; } }

.header-contact__icon .icon { font-size: 16px; }

@media (max-width: 767px) { .header-contact__icon .icon { font-size: 14px; } }

.header-contact__text a { transition: color 0.3s; }

.no-touch .header-contact__text a:hover { color: #8dc63f; }

.header-contact__small { text-decoration: underline; color: #353535; font-size: 0.875em; }

.lang li { display: inline-block; }

.lang a { transition: color 0.3s; }

.no-touch .lang a:hover { color: #353535; }

.lang a.is-active { color: #353535; }

.burger { display: block; width: 28px; height: 28px; position: relative; }

.burger span:after, .burger span:before { content: ""; position: absolute; left: 0; }

.burger span:after { top: 5px; }

.burger span:before { bottom: 5px; }

.burger span { display: block; }

.burger span, .burger span:after, .burger span:before { width: 100%; height: 2px; background-color: currentColor; backface-visibility: hidden; border-radius: 2px; }

.footer { background-color: #fafafa; }

.footer__top { border-bottom: 1px solid  #dcdcdc; }

.footer__inner { display: flex; justify-content: flex-start; align-items: normal; flex-direction: row; flex-wrap: wrap; padding-top: 40px; padding-bottom: 40px; margin-left: -15px; margin-right: -15px; }

@media (max-width: 1199px) { .footer__inner { padding-top: 30px; padding-bottom: 30px; } }

@media (max-width: 991px) { .footer__inner { padding-top: 20px; padding-bottom: 20px; } }

.footer__nav { display: flex; justify-content: flex-start; align-items: normal; flex-direction: row; flex-wrap: wrap; width: 75%; }

@media (max-width: 1199px) { .footer__nav { width: 100%; margin-bottom: 25px; } }

.footer__contacts { width: 25%; display: flex; justify-content: flex-start; align-items: center; flex-direction: column; flex-wrap: nowrap; }

@media (max-width: 1199px) { .footer__contacts { width: 100%; align-items: normal; } }

.footer__contacts .footer__block { width: auto; }

@media (max-width: 1199px) { .footer__contacts .footer__block { width: 25%; } }

@media (max-width: 767px) { .footer__contacts .footer__block { width: 50%; } }

@media (max-width: 1199px) { .footer__contacts-in { display: flex; justify-content: flex-start; align-items: normal; flex-direction: row; flex-wrap: wrap; } }

.footer__block { width: 25%; padding-left: 15px; padding-right: 15px; }

.footer__block:not(:last-child) { margin-bottom: 25px; }

@media (max-width: 767px) { .footer__block { width: 50%; } }

.footer__title { margin-bottom: 20px; }

@media (max-width: 991px) { .footer__title { margin-bottom: 10px; } }

.footer__bottom-in { display: flex; justify-content: flex-start; align-items: center; flex-direction: row; flex-wrap: nowrap; padding-top: 8px; padding-bottom: 8px; }

@media (max-width: 1199px) { .footer__bottom-in { flex-direction: column; } }

.footer__auth, .footer__text { width: 330px; }

@media (max-width: 1199px) { .footer__auth, .footer__text { width: 100%; } }

.footer__copy { width: calc(100% - 660px); padding-left: 15px; padding-right: 15px; text-align: center; font-weight: 300; font-size: 12px; }

@media (max-width: 1199px) { .footer__copy { width: 100%; margin-bottom: 10px; } }

.footer__text { font-size: 0.875em; color: #353535; text-align: right; }

@media (max-width: 1199px) { .footer__text { text-align: center; margin-bottom: 10px; } }

.footer__auth { font-size: 13px; display: flex; justify-content: flex-start; align-items: center; flex-direction: row; flex-wrap: nowrap; }

@media (max-width: 1199px) { .footer__auth { justify-content: center; order: 2; } }

.footer__auth-icon { color: #fff; background-color: #000; display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; width: 40px; height: 40px; margin-right: 15px; }

.footer__auth-icon .icon { font-size: 21px; }

.footer__auth-text a { text-decoration: underline; color: #6ab4ff; }

.nav { font-weight: 700; }

.nav ul { display: flex; justify-content: space-between; align-items: center; flex-direction: row; flex-wrap: nowrap; }

@media (max-width: 1199px) { .nav ul { display: block; } }

.nav li { position: relative; }

.no-touch .nav li:hover > ul { visibility: visible; opacity: 1; }

@media (max-width: 1199px) { .nav li { padding-top: 8px; padding-bottom: 8px; } }

.nav li > ul { position: absolute; display: block; z-index: 1; top: 100%; left: 0; box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.19); background-color: #fff; padding: 20px; color: #353535; width: 430px; }

.is-ready .nav li > ul { transition: opacity 0.3s, visibility 0.3s, max-height 0.5s; }

@media (min-width: 1200px) { .nav li > ul { visibility: hidden; opacity: 0; } }

@media (max-width: 1199px) { .nav li > ul { position: static; width: auto; background-color: transparent; box-shadow: none; color: #fff; padding-top: 0; padding-bottom: 0; font-size: .8em; max-height: 0; overflow: hidden; } }

.nav li > ul.is-open { visibility: visible; opacity: 1; }

.nav li > ul li { padding-top: .5em; padding-bottom: .5em; }

.nav li > ul a { height: auto; display: inline; }

.no-touch .nav li > ul a:hover { text-decoration: none; }

.nav li:nth-child(4) ~ li > ul { left: auto; right: 0; }

.nav a { display: flex; justify-content: flex-start; align-items: center; flex-direction: row; flex-wrap: nowrap; height: 75px; padding-right: 10px; transition: color 0.3s; }

@media (max-width: 1199px) { .nav a { display: inline; height: auto; padding-right: 0; } }

.no-touch .nav a:hover { color: #8dc63f; }

.nav a.is-active { color: #8dc63f; }

.nav--footer { color: #353535; font-size: 0.875em; font-weight: 300; }

.nav--footer ul { display: block; }

@media (max-width: 1199px) { .nav--footer li { padding-top: 0; padding-bottom: 0; } }

.nav--footer li:not(:last-child) { margin-bottom: 10px; }

.nav--footer a { display: inline; height: auto; }

.no-touch .nav--footer a:hover { text-decoration: none; color: #353535; }

.nav--footer a.is-active { text-decoration: none; color: #353535; }

.nav--content { color: #6ab4ff; text-decoration: underline; font-size: 0.875em; font-weight: 400; }

.nav--content ul { flex-wrap: wrap; }

@media (max-width: 1199px) { .nav--content ul { display: flex; } }

.nav--content li { width: 50%; padding-right: 10px; margin-bottom: .5em; }

@media (max-width: 1199px) { .nav--content li { padding-top: 0; padding-bottom: 0; } }

.nav--content a { display: inline; height: auto; padding-right: 0; }

.text-block__title { margin-bottom: 15px; }

@media (max-width: 991px) { .text-block__title { margin-bottom: 10px; } }

.text-block__text { margin-bottom: 20px; }

@media (max-width: 991px) { .text-block__text { margin-bottom: 15px; } }

.btn { display: inline-block; text-align: center; color: #fff; min-width: 250px; font-weight: 700; padding: 0.85em 1em; border-radius: 25px; background-color: #8dc63f; border: 1px solid #8dc63f; transition: background-color 0.3s, border-color 0.3s, color 0.3s, opacity 0.3s; }

.no-touch .btn:hover:not(.btn--no-hover) { opacity: .7; }

@media (max-width: 575px) { .btn { min-width: 0; width: 100%; } }

.btn--full { min-width: 0; width: 100%; }

.hero { position: relative; background-color: #fafafa; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; background-position: 70% 50%; padding-top: 30px; padding-bottom: 50px; }

.hero__breadcrumbs, .section__breadcrumbs { margin-bottom: 15px; }

.hero__bg { position: absolute; z-index: 0; top: 0; bottom: 0; width: 50%; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; }

@media (max-width: 1199px) { .hero__bg::before { content: ''; position: absolute; top: 0; left: 0; bottom: 0; right: 0; z-index: 0; background-color: rgba(255, 255, 255, 0.7); } }

@media (max-width: 991px) { .hero__bg { background-position: 60% 50%; } }

@media (max-width: 767px) { .hero__bg { background-position: 70% 50%; } }

@media (max-width: 575px) { .hero__bg { left: 0; right: 0; width: auto; height: 50%; display: none; } }

.hero__bg-left { left: 0; }

.hero__bg-right { right: 0; }

@media (max-width: 1499px) { .hero__bg-right { background-position: 0 50%; } }

@media (max-width: 575px) { .hero__bg-right { top: auto; bottom: 0; } }

.hero__inner { position: relative; z-index: 1; }

.hero__content { max-width: 295px; }

@media (max-width: 767px) { .hero__content { max-width: none; } }

.hero__right { padding-left: 50px; }

@media (max-width: 1199px) { .hero__right { padding-left: 0; } }

.hero__label { position: absolute; z-index: 2; top: 25px; left: 50%; transform: translate(-50%, 0); width: 160px; }

@media (max-width: 1199px) { .hero__label { top: 15px; } }

@media (max-width: 767px) { .hero__label { position: static; transform: translate(0, 0); width: auto; } }

.hero__img { display: none; max-width: 120px; margin-right: 15px; float: left; }

@media (max-width: 575px) { .hero__img { display: inline-block; } }

.hero--home { padding-top: 0; padding-bottom: 0; }

@media (max-width: 1199px) { .hero--home .hero__inner { padding-top: 50px; } }

@media (max-width: 767px) { .hero--home .hero__inner { padding-top: 50px; padding-bottom: 50px; } }

.hero--home .hero__left, .hero--home .hero__right { padding-top: 25%; padding-bottom: 25%; }

@media (max-width: 767px) { .hero--home .hero__left, .hero--home .hero__right { padding-top: 0; padding-bottom: 0; margin-bottom: 30px; } }

.hero-label { color: #8dc63f; text-align: center; }

@media (max-width: 575px) { .hero-label { display: flex; justify-content: flex-start; align-items: center; flex-direction: row; flex-wrap: nowrap; } }

.hero-label__icon { width: 55px; height: 55px; border-radius: 50%; background-color: #fff; display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; margin-left: auto; margin-right: auto; margin-bottom: 10px; overflow: hidden; position: relative; }

@media (max-width: 575px) { .hero-label__icon { margin-left: 0; margin-right: 1em; margin-bottom: 0; } }

.hero-label__icon .icon { font-size: 1.5625em; position: relative; z-index: 1; }

.hero-label__icon::before { content: ''; background-color: #e8e8e8; position: absolute; right: 0; top: 0; bottom: 0; width: 50%; z-index: 0; }

.hero-label__title { font-weight: 700; font-size: 0.875em; }

.section { padding-top: 60px; padding-bottom: 60px; position: relative; }

@media (max-width: 1399px) { .section { padding-top: 50px; padding-bottom: 50px; } }

@media (max-width: 1199px) { .section { padding-top: 40px; padding-bottom: 40px; } }

@media (max-width: 991px) { .section { padding-top: 30px; padding-bottom: 30px; } }

.section__title, .section__nav { margin-bottom: 30px; }

@media (max-width: 991px) { .section__title, .section__nav { margin-bottom: 15px; } }

.section__nav { position: sticky; top: 75px; z-index: 9; }

@media (max-width: 1199px) { .section__nav { top: 55px; } }

@media (max-width: 575px) { .section__nav { position: static; } }

.section__bg { background-color: #757577; position: absolute; top: 0; left: 0; bottom: 0; right: 0; z-index: 0; }

.section__bg iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.section__inner { position: relative; z-index: 1; }

.section__block:not(:last-child) { margin-bottom: 30px; }

@media (max-width: 767px) { .section__block:not(:last-child) { margin-bottom: 15px; } }

.section__contacts { height: 100%; display: flex; justify-content: flex-start; align-items: normal; flex-direction: column; flex-wrap: nowrap; }

@media (max-width: 991px) { .section__contacts { height: auto; margin-bottom: 30px; } }

@media (max-width: 767px) { .section__contacts { margin-bottom: 15px; } }

.section__contacts .map { flex-grow: 1; }

.section--gray { background-color: #fafafa; }

.section--sm { padding-top: 30px; padding-bottom: 30px; }

@media (max-width: 1399px) { .section--sm { padding-top: 30px; padding-bottom: 30px; } }

@media (max-width: 1199px) { .section--sm { padding-top: 30px; padding-bottom: 30px; } }

@media (max-width: 991px) { .section--sm { padding-top: 30px; padding-bottom: 30px; } }

@media (max-width: 575px) { .s-contacts { padding-top: 0; } }

@media (max-width: 575px) { .s-contacts .section__bg { position: relative; width: 100%; top: auto; left: auto; right: auto; bottom: auto; padding-top: 70%; margin-bottom: 15px; } }

@media (max-width: 767px) { .services .row, .services .steps, .services .contacts { margin-left: -5px; margin-right: -5px; } }

@media (max-width: 767px) { .services [class*="col-"] { padding-left: 5px; padding-right: 5px; } }

.services-tabs { overflow: hidden; }

.services-tabs__tabs { margin-bottom: 60px; position: relative; }

.services-tabs__tabs::before { content: ''; border-top: 1px solid #8dc63f; bottom: 0; left: 50%; transform: translate(-50%, 0); z-index: 0; width: 300vw; position: absolute; }

@media (max-width: 1399px) { .services-tabs__tabs { margin-bottom: 50px; } }

@media (max-width: 1199px) { .services-tabs__tabs { margin-bottom: 40px; } }

@media (max-width: 991px) { .services-tabs__tabs { margin-bottom: 30px; } }

.services-tabs__item { max-height: 0; overflow: hidden; opacity: 0; transition: opacity 0.3s; }

.services-tabs__item.is-active { max-height: none; opacity: 1; }

@media (max-width: 1199px) { .services-tabs-list { overflow: auto; } }

.services-tabs-list__list { display: flex; justify-content: flex-start; align-items: normal; flex-direction: row; flex-wrap: nowrap; }

@media (max-width: 1199px) { .services-tabs-list__list { width: 1024px; } }

.services-tabs-list__btn { text-align: center; display: block; text-transform: uppercase; font-size: 0.875em; border: 1px solid transparent; border-bottom: 0; border-top-left-radius: 25px; border-top-right-radius: 25px; background-color: #fafafa; padding: 1.35em; min-width: 360px; transition: background-color 0.3s, color 0.3s, border-color 0.3s; }

@media (max-width: 1499px) { .services-tabs-list__btn { min-width: 0; padding-left: 2.5em; padding-right: 2.5em; } }

@media (max-width: 1199px) { .services-tabs-list__btn { padding-left: 1em; padding-right: 1em; } }

.services-tabs-list__btn.is-active { font-weight: 700; background-color: #fff; border-color: #8dc63f; box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.12); position: relative; z-index: 1; }

.no-touch .services-tabs-list__btn:hover { font-weight: 700; }

.card { display: flex; justify-content: flex-start; align-items: normal; flex-direction: column; flex-wrap: nowrap; overflow: hidden; border-radius: 25px; box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1); text-align: center; border: 1px solid transparent; height: calc(100% - 30px); margin-bottom: 30px; transition: border-color 0.5s, height 0.5s, box-shadow 0.3s; }

@media (max-width: 767px) { .card { margin-bottom: 15px; height: calc(100% - 15px); } }

@media (max-width: 575px) { .card { border-radius: 15px; } }

.no-touch .card:hover:not(.card--shadow-mod) { border-color: #8dc63f; }

.no-touch .card:hover:not(.card--lg) .card__btn { max-height: 100px; }

.card__content { flex-grow: 1; background-color: #fff; padding: 25px; height: 220px; display: flex; justify-content: center; align-items: normal; flex-direction: column; flex-wrap: nowrap; }

@media (max-width: 1199px) { .card__content { padding: 20px; } }

@media (max-width: 991px) { .card__content { padding: 15px; } }

@media (max-width: 575px) { .card__content { height: auto; } }

.card__img { display: block; background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; padding-top: 60%; position: relative; }

@media (max-width: 575px) { .card__img { padding-top: 90%; } }

.card__img img { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); max-height: 100%; z-index: 1; }

.card__title { margin-bottom: 10px; display: block; }

.card__text { margin-bottom: 15px; max-height: 3em; overflow: hidden; display: block; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }

@media (max-width: 991px) { .card__text { margin-bottom: 10px; } }

.card__btn { max-height: 0; overflow: hidden; transition: max-height 0.5s; }

.card__date { margin-bottom: 15px; display: block; }

@media (max-width: 991px) { .card__date { margin-bottom: 10px; } }

.card--shadow-mod { box-shadow: 0.551px 1.923px 6px 0px rgba(0, 0, 0, 0.09); }

.no-touch .card--shadow-mod:hover { box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15); }

.card--lg { text-align: left; }

.card--lg .card__btn { max-height: none; overflow: visible; }

.card--lg .card__content { justify-content: flex-start; }

.card--lg .card__text { flex-grow: 1; }

@media (max-width: 575px) { .steps { margin-left: -5px; margin-right: -5px; } }

@media (max-width: 575px) { .steps__item { padding-left: 5px; padding-right: 5px; } }

.steps__item::after { content: ''; top: 72px; transform: translate(50%, 0); width: 65px; right: 0; position: absolute; border-top: 1px dashed #757577; }

@media (max-width: 991px) { .steps__item::after { top: 55px; } }

@media (max-width: 575px) { .steps__item::after { width: 50px; top: 45px; } }

.steps__item:nth-child(4n-4)::after { content: ''; display: none; }

@media (max-width: 767px) { .steps__item:nth-child(2n-2)::after { content: ''; display: none; } }

.step { text-align: center; max-width: 245px; margin-left: auto; margin-right: auto; margin-bottom: 30px; }

@media (max-width: 767px) { .step { margin-bottom: 15px; } }

.step__nmb { text-align: left; color: #8dc63f; font-weight: 700; margin-bottom: -10px; }

.step__icon { display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; margin-left: auto; margin-right: auto; border-radius: 50%; width: 110px; height: 110px; border: 1px solid #e2e2e2; color: #fff; margin-bottom: 15px; position: relative; overflow: hidden; }

@media (max-width: 991px) { .step__icon { margin-bottom: 10px; width: 90px; height: 90px; } }

@media (max-width: 575px) { .step__icon { width: 70px; height: 70px; } }

.step__icon::before { content: ''; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 0; background-color: #8dc63f; width: calc(100% - 10px); height: calc(100% - 10px); border-radius: 50%; }

.step__icon .icon { font-size: 2.8125em; position: relative; z-index: 1; }

.step__title { margin-bottom: 10px; }

.scrollbar { overflow-x: hidden; padding-right: 40px; text-align: justify; }

.scrollbar::-webkit-scrollbar { width: 6px; }

.scrollbar::-webkit-scrollbar-track { background-color: #e2e2e2; box-shadow: inset 2px 0 0px #fff, inset -2px 0 0px #fff; outline: none; }

.scrollbar::-webkit-scrollbar-thumb { background-color: #8dc63f; transition: background-color 0.3s; }

@media (max-width: 1199px) { .scrollbar { padding-right: 30px; } }

@media (max-width: 991px) { .scrollbar { padding-right: 15px; } }

.scrollbar .simplebar-scrollbar { width: 100%; right: 0; z-index: 1; }

.scrollbar .simplebar-scrollbar::before { content: ''; top: 0 !important; bottom: 0 !important; opacity: 1; background-color: #8dc63f; border-radius: 0; z-index: 1; }

.scrollbar .simplebar-track { width: 6px; }

.scrollbar .simplebar-track::after { content: ''; position: absolute; top: 0; bottom: 0; height: 100%; left: 50%; transform: translate(-50%, 0); z-index: 0; width: 2px; background-color: #e2e2e2; }

.two-cols { position: relative; }

.two-cols__col-abs { width: calc(50% - 15px); position: absolute; top: 0; bottom: 0; right: 0; }

@media (max-width: 767px) { .two-cols__col-abs { position: static; width: 100%; } }

@media (max-width: 767px) { .two-cols__col { margin-bottom: 15px; } }

.two-cols__img { background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; height: 100%; }

@media (max-width: 767px) { .two-cols__img { padding-top: 50%; } }

.contacts-block { background-color: #fff; border-radius: 25px; box-shadow: 0.551px 1.923px 25px 0px rgba(0, 0, 0, 0.14); padding: 50px 40px; }

@media (max-width: 1399px) { .contacts-block { padding: 40px; } }

@media (max-width: 1199px) { .contacts-block { padding: 30px; } }

@media (max-width: 991px) { .contacts-block { padding: 20px; } }

@media (max-width: 767px) { .contacts-block { padding: 15px; } }

.contacts-block__title { margin-bottom: 10px; }

.contacts-block__text { margin-bottom: 25px; padding-bottom: 15px; position: relative; }

.contacts-block__text::before { content: ''; position: absolute; bottom: 0; left: 0; z-index: 1; background-color: #8dc63f; height: 2px; width: 100px; }

@media (max-width: 991px) { .contacts-block__text { margin-bottom: 15px; } }

.contact { position: relative; padding-left: 25px; }

.contact:not(:last-child) { margin-bottom: 25px; }

@media (max-width: 991px) { .contact:not(:last-child) { margin-bottom: 15px; } }

.contact__title { margin-bottom: 10px; }

.contact__icon { position: absolute; z-index: 1; color: #8dc63f; top: 0; left: 0; }

.contact__text a { transition: color 0.3s; }

.no-touch .contact__text a:hover { color: #8dc63f; }

.contacts { margin-bottom: 50px; }

@media (max-width: 1399px) { .contacts { margin-bottom: 40px; } }

@media (max-width: 1199px) { .contacts { margin-bottom: 30px; } }

@media (max-width: 767px) { .contacts { margin-bottom: 15px; margin-left: -5px; margin-right: -5px; } }

.contacts__item { width: 25%; padding-left: 15px; padding-right: 15px; margin-bottom: 15px; }

@media (max-width: 1399px) { .contacts__item { width: 50%; } }

@media (max-width: 767px) { .contacts__item { padding-left: 5px; padding-right: 5px; } }

@media (max-width: 575px) { .contacts__item { width: 100%; } }

@media (max-width: 575px) { .contact-full { text-align: center; } }

.contact-full__icon { background-color: #f2f2f2; border-radius: 50%; display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; width: 60px; height: 60px; margin-bottom: 20px; }

@media (max-width: 991px) { .contact-full__icon { margin-bottom: 15px; } }

@media (max-width: 767px) { .contact-full__icon { width: 50px; height: 50px; } }

@media (max-width: 575px) { .contact-full__icon { margin-left: auto; margin-right: auto; } }

.contact-full__icon .icon { font-size: 1.5625em; }

.contact-full__title { position: relative; padding-bottom: 15px; margin-bottom: 15px; }

.contact-full__title::before { content: ''; position: absolute; background-color: #8dc63f; height: 2px; left: 0; bottom: 0; width: 55px; }

@media (max-width: 575px) { .contact-full__title::before { left: 50%; transform: translate(-50%, 0); } }

.contact-full__text { font-size: 1.1875em; }

.contact-full__text a { transition: color 0.3s; }

.no-touch .contact-full__text a:hover { color: #8dc63f; }

.map { position: relative; background-color: #757577; padding-top: 50%; }

@media (max-width: 575px) { .map { padding-top: 70%; } }

.map iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1; }

.breadcrumbs { font-size: 0.875em; }

.breadcrumbs li { display: inline-block; }

.breadcrumbs .is-active { pointer-events: none; color: #d1d1d1; }

.pagination { font-size: 0.875em; color: #353535; text-align: center; }

.pagination li { display: inline-block; vertical-align: middle; margin-left: 5px; margin-right: 5px; }

@media (max-width: 575px) { .pagination li { margin-left: 1px; margin-right: 1px; } }

.pagination a { display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; background-color: #fff; border-radius: 20px; height: 40px; min-width: 40px; box-shadow: 0.276px 0.961px 5px 0px rgba(0, 0, 0, 0.13); transition: background-color 0.3s, color 0.3s; }

@media (max-width: 575px) { .pagination a { height: 30px; min-width: 30px; } }

.pagination a.is-active { background-color: #8dc63f; color: #fff; }

.no-touch .pagination a:hover { background-color: #8dc63f; color: #fff; }

.pagination a.next, .pagination a.prev { background-color: transparent; box-shadow: none; }

.pagination a.next::before, .pagination a.prev::before { content: ''; width: 10px; height: 10px; border-left: 1px solid currentColor; border-bottom: 1px solid currentColor; transform: rotate(45deg); display: block; margin-left: .3em; }

.pagination a.next { transform: scaleX(-1); }

.article { font-size: 0.875em; text-align: justify; }

.article:after { content: " "; display: table; clear: both; }

.article article:after { content: " "; display: table; clear: both; }

.article p:not(:last-child), .article ul:not(:last-child), .article ol:not(:last-child), .article img:not(:last-child), .article blockquote:not(:last-child), .article figure:not(:last-child), .article h1:not(:last-child), .article h2:not(:last-child), .article h3:not(:last-child), .article h4:not(:last-child), .article h5:not(:last-child), .article h6:not(:last-child) { margin-bottom: 20px; }

@media (max-width: 991px) { .article p:not(:last-child), .article ul:not(:last-child), .article ol:not(:last-child), .article img:not(:last-child), .article blockquote:not(:last-child), .article figure:not(:last-child), .article h1:not(:last-child), .article h2:not(:last-child), .article h3:not(:last-child), .article h4:not(:last-child), .article h5:not(:last-child), .article h6:not(:last-child) { margin-bottom: 15px; } }

.article article:not(:last-child) { margin-bottom: 55px; }

@media (max-width: 1399px) { .article article:not(:last-child) { margin-bottom: 40px; } }

@media (max-width: 1199px) { .article article:not(:last-child) { margin-bottom: 30px; } }

@media (max-width: 991px) { .article article:not(:last-child) { margin-bottom: 20px; } }

.article figure { font-size: 0; }

.article figure img { width: 100%; }

.article ul { overflow: hidden; }

.article ul li { padding-left: 1em; position: relative; }

.article ul li::before { content: '\2022'; display: inline-block; }

.article ul li::before { margin-right: .5em; color: #8dc63f; font-size: 2em; position: absolute; top: -.3em; left: 0; }

.article ol { counter-reset: listtext; }

.article ol > li:before { content: counter(listtext) ")"; counter-increment: listtext; }

.article ol li::before { margin-right: .5em; }

.article img.is-left { float: left; margin-right: 30px; }

@media (max-width: 991px) { .article img.is-left { margin-right: 0; float: none; width: 100%; } }

.article img.is-right { float: right; margin-left: 30px; }

@media (max-width: 991px) { .article img.is-right { margin-left: 0; float: none; width: 100%; } }

.article a { color: #6ab4ff; text-decoration: underline; }

.article table { max-width: 695px; }

.article table td, .article table th { text-align: center; vertical-align: middle; padding: 0.625em; border: 1px solid #e2e2e2; }

.article table th { font-weight: 700; }

.article--seo { max-height: 520px; overflow: auto; color: #353535; }

.article--seo h1:not(:last-child), .article--seo h2:not(:last-child), .article--seo h3:not(:last-child), .article--seo h4:not(:last-child), .article--seo h5:not(:last-child), .article--seo h6:not(:last-child) { margin-bottom: 15px; }

@media (max-width: 991px) { .article--seo h1:not(:last-child), .article--seo h2:not(:last-child), .article--seo h3:not(:last-child), .article--seo h4:not(:last-child), .article--seo h5:not(:last-child), .article--seo h6:not(:last-child) { margin-bottom: 10px; } }

.block, .popup__inner { background-color: #fff; border-radius: 15px; padding: 30px 40px; box-shadow: 0.551px 1.923px 6px 0px rgba(0, 0, 0, 0.09); }

@media (max-width: 1399px) { .block, .popup__inner { padding: 20px 30px; } }

@media (max-width: 991px) { .block, .popup__inner { padding: 15px 20px; } }

.block__title { margin-bottom: 20px; }

@media (max-width: 991px) { .block__title { margin-bottom: 10px; } }

.block__text:not(:last-child) { margin-bottom: 25px; }

@media (max-width: 991px) { .block__text:not(:last-child) { margin-bottom: 15px; } }

.block__text .list:not(:last-child), .block__text p:not(:last-child) { margin-bottom: 1em; }

.block__btn { text-align: center; }

.block--sm { padding: 5px 20px; }

@media (max-width: 1399px) { .block--sm { padding: 5px 20px; } }

@media (max-width: 991px) { .block--sm { padding: 5px 15px; } }

.block--gray { box-shadow: none; background-color: #fafafa; }

.block--lg, .popup__inner { box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.19); padding-top: 50px; padding-bottom: 50px; }

@media (max-width: 1399px) { .block--lg, .popup__inner { padding-top: 40px; padding-bottom: 40px; } }

@media (max-width: 1199px) { .block--lg, .popup__inner { padding-top: 30px; padding-bottom: 30px; } }

.block-nav__list { display: flex; justify-content: space-between; align-items: center; flex-direction: row; flex-wrap: wrap; }

@media (max-width: 1399px) { .block-nav__list { justify-content: flex-start; } }

@media (max-width: 1399px) { .block-nav__item { width: 33.33333%; } }

@media (max-width: 1199px) { .block-nav__item { width: 50%; } }

@media (max-width: 575px) { .block-nav__item { width: 100%; } }

.block-nav__link { transition: color 0.3s; }

.block-nav__link.is-active, .no-touch .block-nav__link:hover { color: #8dc63f; }

.prev, .next { width: 25px; height: 25px; box-shadow: 0.276px 0.961px 5px 0px rgba(0, 0, 0, 0.13); border-radius: 50%; display: flex; justify-content: center; align-items: center; flex-direction: row; flex-wrap: nowrap; transition: background-color 0.3s, color 0.3s; }

.no-touch .prev:hover, .no-touch .next:hover { color: #fff; background-color: #8dc63f; }

.prev::before, .next::before { content: ''; width: 8px; height: 8px; border-left: 2px solid currentColor; border-bottom: 2px solid currentColor; transform: rotate(45deg); display: block; margin-left: .1em; }

.prev[disabled], .next[disabled] { opacity: .5; }

.next { transform: scaleX(-1); }

.slider__wrap { position: relative; padding-left: 45px; padding-right: 45px; }

.slider__controls { position: absolute; top: 50%; transform: translate(0, -50%); width: 100%; left: 0; }

.slider__prev, .slider__next { position: absolute; top: 50%; transform: translate(0, -50%); z-index: 1; }

.slider__prev { left: 0; }

.slider__next { right: 0; transform: translate(0, -50%) scaleX(-1); }

.form { text-align: center; }

.form__title { margin-bottom: 10px; }

.form__subttl { margin-bottom: 30px; }

@media (max-width: 991px) { .form__subttl { margin-bottom: 15px; } }

.form__field:not(:last-child) { margin-bottom: 15px; }

.form__block-title { text-align: left; margin-bottom: 15px; }

@media (max-width: 991px) { .form__block-title { margin-bottom: 10px; } }

.form__block:not(:last-child) { margin-bottom: 25px; }

@media (max-width: 991px) { .form__block:not(:last-child) { margin-bottom: 15px; } }

.form__radios { display: flex; justify-content: flex-start; align-items: center; flex-direction: row; flex-wrap: nowrap; }

.form__radios .input:not(:last-child) { margin-right: 45px; }

.form__chekcboxes .input:not(:last-child) { margin-bottom: 10px; }

.input { display: block; text-align: left; position: relative; }

.input input[type="text"], .input input[type="tel"], .input input[type="email"], .input input[type="password"], .input input[type="file"], .input input[type="search"] { width: 100%; height: 3.125em; background-color: #fafafa; border-radius: 25px; padding: 0.85em 1.875em; font-size: 0.875em; }

.input--radio, .input--checkbox { user-select: none; padding-left: 1.5em; cursor: pointer; }

.input--radio input[type="checkbox"], .input--radio input[type="radio"], .input--checkbox input[type="checkbox"], .input--checkbox input[type="radio"] { visibility: hidden; opacity: 0; position: absolute; }

.input--radio input[type="checkbox"]:checked + span::after, .input--radio input[type="radio"]:checked + span::after, .input--checkbox input[type="checkbox"]:checked + span::after, .input--checkbox input[type="radio"]:checked + span::after { content: ''; position: absolute; display: block; }

.input--radio span::before, .input--checkbox span::before { content: ''; border: 1px solid currentColor; width: .9em; height: .9em; top: .2em; left: 0; background-color: #fff; position: absolute; display: block; }

.input--checkbox input[type="checkbox"]:checked + span::after { width: 0.9em; height: 0.3em; border-left: 0.1em solid #8dc63f; border-bottom: 0.1em solid #8dc63f; transform: rotate(-45deg); top: .3em; left: .2em; }

.input--radio input[type="radio"]:checked + span::after { width: .5em; height: .5em; background-color: #8dc63f; top: .4em; left: .2em; border-radius: 50%; }

.input--radio span::before { border-radius: 50%; }

.custom-select { position: relative; user-select: none; cursor: pointer; font-size: 0.875em; }

.custom-select select { visibility: hidden; opacity: 0; position: absolute; }

.custom-select.is-open .custom-select__panel { visibility: visible; opacity: 1; }

.custom-select__opener { background-color: #fafafa; border-radius: 25px; padding: 0.85em 1.875em; padding-right: 2.5em; height: 3.125em; position: relative; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.custom-select__opener::after { content: ''; width: 6px; height: 6px; border-left: 1px solid currentColor; border-bottom: 1px solid currentColor; transform: rotate(-45deg); position: absolute; top: 50%; transform: translate(0, -50%) rotate(-45deg); right: 1.8em; z-index: 1; pointer-events: none; }

.custom-select__panel { position: absolute; width: 100%; z-index: 1; background-color: #fafafa; top: calc(100% + 10px); left: 0; border-radius: 25px; max-height: 300px; overflow: auto; visibility: hidden; opacity: 0; }

.is-ready .custom-select__panel { transition: opacity 0.3s, visibility 0.3s; }

.custom-select__option { cursor: pointer; padding: 1em 1.5em; transition: background-color 0.3s, color 0.3s; }

.no-touch .custom-select__option:hover { color: #8dc63f; }

.popup { position: fixed; z-index: 15; overflow: auto; top: 0; bottom: 0; left: 0; right: 0; padding: 15px; background-color: rgba(0, 0, 0, 0.9); text-align: center; font-size: 0; visibility: hidden; opacity: 0; }

.popup:before { content: ""; display: inline-block; vertical-align: middle; width: 0; height: 100%; }

.is-ready .popup { transition: opacity 0.3s, visibility 0.3s; }

.popup.is-active { visibility: visible; opacity: 1; }

.popup__inner { box-shadow: none; display: inline-block; vertical-align: middle; font-size: 16px; position: relative; width: 100%; max-width: 450px; }

@media (max-width: 767px) { .popup__inner { font-size: 14px; } }

.popup__title { margin-bottom: 10px; }

.popup__subttl { margin-bottom: 30px; }

@media (max-width: 991px) { .popup__subttl { margin-bottom: 15px; } }

.popup__close { color: #353535; }

.error { padding-top: 15%; padding-bottom: 15%; }

@media (max-width: 575px) { .error { padding-bottom: 0; padding-top: 0px; } }

.error__text-block { margin-bottom: 15px; }

.error__nav { max-width: 350px; }

.error-text { font-family: 'arciform', sans-serif; color: #8dc63f; font-size: 218px; font-size: calc(100px + (218 - 100) * ((100vw - 320px) / (1399 - 320))); position: relative; line-height: 1; text-align: center; display: inline-block; }

@media (max-width: 320px) { .error-text { font-size: 100px; } }

@media (min-width: 1399px) { .error-text { font-size: 218px; } }

.error-text::before { content: attr(data-text); position: absolute; z-index: 0; top: .03em; right: -.03em; color: #78b02b; }

.error-text__title { position: relative; z-index: 1; }

.overflow-hidden { overflow: hidden; }

.text-center { text-align: center; }

.pointer-events-none { pointer-events: none; }

.pt-0 { padding-top: 0 !important; }

.pb-0 { padding-bottom: 0 !important; }

@media (max-width: 991px) { .mb-md { margin-bottom: 30px; } }

@media (max-width: 767px) { .mb-md { margin-bottom: 15px; } }
