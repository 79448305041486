body {
  background: $white;
  line-height: 1.4;
  font-size: $fz;
  min-width: 320px;
  @include reg;
  color: $c-text;
  @include no-scroll;
  @include sm {
    font-size: $fz-sm-px; } }

.out {
  @include lg {
    padding-top: $header-height-lg; } }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  &.is-loaded:not(.no-fade-in) {
    animation: fadeIn 1s; } }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px; }

.logo {
  font-size: 0;
  display: inline-block;
  max-width: 185px;
  @include lg {
    max-width: 150px; }
  // img
  //// max-height: 70px
  @include xss {
    width: 80px; }
  // +xss
 }  //   width: 60px

.close {
  @include icon-close(20, 2, currentColor);
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
  @include tr(color .3s);
  @include hover {
    color: $accent; } }

.date {
  font-size: $fz-xs; }

.link-arrow {
  $this: &;
  @include inline-flex(flex-start, center);
  font-weight: 700;
  color: $accent;
  @include hover {
    &:not(#{$this}--no-hover) {
      #{$this}__arr {
        transform: translate(10px, 0); } } }
  &__title {
    margin-right: em(20);
    display: block; }
  &__arr {
    width: 60px;
    position: relative;
    display: block;
    @include tr(width .5s, transform .5s);
    @include before {
      width: 100%;
      background-color: currentColor;
      height: 1px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0; }
    @include after {
      @include icon-arrow(10, 10, 1, currentColor, 225);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%) rotate(225deg);
      right: 0; } } }

.list {
  li {
    @include list-bullet;
    padding-left: 1em;
    position: relative;
    @include notlast {
      margin-bottom: 1em; }
    &::before {
      margin-right: .5em;
      color: $accent;
      font-size: 2em;
      position: absolute;
      top: -.3em;
      left: 0; } }
  &--left {
    margin-left: -1em; } }

.gallery-thumb {
  $this: &;
  // +cover
  // border-radius: 5px
  padding-top: 7%;
  // height: 200px
  position: relative;
  overflow: hidden;
  @include hover {
    #{$this}__cover {
      @include visible; } }
  &__img, &__cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 5px;
    position: absolute;
    display: block;
    border-radius: 5px; }
  &__img {
    @include cover;
    z-index: 0; }
  &__cover {
    background-color: rgba($black, .5);
    @include flex(center, center);
    color: $accent;
    @include tr(opacity .3s, visibility .3s);
    @include hidden;
    z-index: 1;
    .icon {
      font-size: em(24); } } }

.link {
  font-weight: 700;
  @include tr(color .3s, opacity .3s);
  color: $accent;
  @include hover {
    text-decoration: underline; } }

