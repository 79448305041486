.two-cols {
  position: relative;
  &__col-abs {
    width: calc(50% - 15px);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    @include sm {
      position: static;
      width: 100%; } }
  &__col {
    @include sm {
      margin-bottom: 15px; } }
  &__img {
    @include cover;
    height: 100%;
    @include sm {
      padding-top: 50%; } } }
