.services {
  .row {
    @include sm {
      margin-left: -5px;
      margin-right: -5px; } }
  [class*="col-"] {
    @include sm {
      padding-left: 5px;
      padding-right: 5px; } } }

.services-tabs {
  overflow: hidden;
  &__tabs {
    margin-bottom: 60px;
    position: relative;
    @include before {
      border-top: 1px solid  $accent;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 0;
      width: 300vw;
      position: absolute; }
    @include xl {
      margin-bottom: 50px; }
    @include lg {
      margin-bottom: 40px; }
    @include md {
      margin-bottom: 30px; } }
  &__item {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    @include tr(opacity .3s);
    &.is-active {
      max-height: none;
      opacity: 1; } } }

.services-tabs-list {
  @include lg {
    overflow: auto; }
  &__list {
    @include flex();
    @include lg {
      width: 1024px; } }
  &__btn {
    text-align: center;
    display: block;
    text-transform: uppercase;
    font-size: $fz-sm;
    border: 1px solid transparent;
    border-bottom: 0;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    background-color: $gray-lightest;
    padding: 1.35em;
    min-width: 360px;
    @include tr(background-color .3s, color .3s, border-color .3s);
    @include xll {
      min-width: 0;
      padding-left: 2.5em;
      padding-right: 2.5em; }
    @include lg {
      padding-left: 1em;
      padding-right: 1em; }
    &.is-active {
      font-weight: 700;
      background-color: $white;
      border-color: $accent;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.12);
      position: relative;
      z-index: 1; }
    @include hover {
      // @extend .services-tabs-list__btn.is-active
      font-weight: 700; } } }
