.contacts-block {
  background-color: $white;
  border-radius: $radius;
  box-shadow: 0.551px 1.923px 25px 0px rgba(0, 0, 0, 0.14);
  padding: 50px 40px;
  @include xl {
    padding: 40px; }
  @include lg {
    padding: 30px; }
  @include md {
    padding: 20px; }
  @include sm {
    padding: 15px; }
  @include xs;
  &__title {
    margin-bottom: 10px; }
  &__text {
    margin-bottom: 25px;
    padding-bottom: 15px;
    position: relative;
    @include before {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: $accent;
      height: 2px;
      width: 100px; }
    @include md {
      margin-bottom: 15px; } } }

.contact {
  position: relative;
  padding-left: 25px;
  @include notlast {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 15px; } }
  &__title {
    margin-bottom: 10px; }
  &__icon {
    position: absolute;
    z-index: 1;
    color: $accent;
    top: 0;
    left: 0;
    .icon {} }
  &__text {
    a {
      @include tr(color .3s);
      @include hover {
        color: $accent; } } } }


.contacts {
  @extend .row;
  margin-bottom: 50px;
  @include xl {
    margin-bottom: 40px; }
  @include lg {
    margin-bottom: 30px; }
  @include sm {
    margin-bottom: 15px;
    margin-left: -5px;
    margin-right: -5px; }
  &__item {
    // @extend .col-xl-3
    // @extend .col-6
    width: 25%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    @include xl {
      width: 50%; }
    @include sm {
      padding-left: 5px;
      padding-right: 5px; }
    @include xs {
      width: 100%; } } }

.contact-full {
  @include xs {
    text-align: center; }
  &__icon {
    background-color: #f2f2f2;
    border-radius: 50%;
    @include flex(center, center);
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
    @include md {
      margin-bottom: 15px; }
    @include sm {
      width: 50px;
      height: 50px; }
    @include xs {
      margin-left: auto;
      margin-right: auto; }
    .icon {
      font-size: em(25); } }
  &__title {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    @include before {
      position: absolute;
      background-color: $accent;
      height: 2px;
      left: 0;
      bottom: 0;
      width: 55px;
      @include xs {
        left: 50%;
        transform: translate(-50%, 0); } } }
  &__text {
    font-size: em(19);
    a {
      @include tr(color .3s);
      @include hover {
        color: $accent; } } } }

.map {
  position: relative;
  background-color: $gray;
  padding-top: 50%;
  @include xs {
    padding-top: 70%; }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; } }


