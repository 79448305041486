.footer {
  $this: &;
  background-color: $gray-lightest;
  &__top {
    border-bottom: 1px solid  #dcdcdc; }
  &__inner {
    @include flex(flex-start, normal, row, wrap);
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: -15px;
    margin-right: -15px;
    @include lg {
      padding-top: 30px;
      padding-bottom: 30px; }
    @include md {
      padding-top: 20px;
      padding-bottom: 20px; } }
  &__nav {
    @include flex(flex-start, normal, row, wrap);
    width: percentage(9/12);
    @include lg {
      width: 100%;
      margin-bottom: 25px; } }
  &__contacts {
    width: percentage(3/12);
    @include flex(flex-start, center, column);
    @include lg {
      width: 100%;
      align-items: normal; }
    #{$this}__block {
      width: auto;
      @include lg {
        width: 25%; }
      @include sm {
        width: 50%; } } }
  &__contacts-in {
    @include lg {
      @include flex(flex-start, normal, row, wrap); } }
  &__block {
    width: 25%;
    padding-left: 15px;
    padding-right: 15px;
    @include notlast {
      margin-bottom: 25px; }
    @include sm {
      width: 50%; } }
  &__title {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; } }
  &__bottom-in {
    @include flex(flex-start, center);
    padding-top: 8px;
    padding-bottom: 8px;
    @include lg {
      flex-direction: column; } }
  &__auth, &__text {
    width: 330px;
    @include lg {
      width: 100%; } }
  &__copy {
    width: calc(100% - 660px);
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    @include lg {
      width: 100%;
      margin-bottom: 10px; } }
  &__text {
    font-size: $fz-sm;
    color: $c-title;
    text-align: right;
    @include lg {
      text-align: center;
      margin-bottom: 10px; } }
  &__auth {
    font-size: 13px;
    @include flex(flex-start, center);
    @include lg {
      justify-content: center;
      order: 2; } }
  &__auth-icon {
    color: $white;
    background-color: $black;
    @include flex(center, center);
    width: 40px;
    height: 40px;
    margin-right: 15px;
    .icon {
      font-size: 21px; } }
  &__auth-text {

    a {
      text-decoration: underline;
      color: $blue; } } }
