.header {
  $this: &;
  position: relative;
  z-index: 10;
  .container {
    height: 100%; }
  @include lg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0; }
  &.is-fixed {
    #{$this}__bottom {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 10; } }
  &__top {
    height: $header-top-height;
    background-color: $white;
    @include lg {
      height: $header-top-height-lg; } }
  &__bottom {
    height: $header-bottom-height;
    box-shadow: inset 1.696px -1.06px 19px 0px rgba(0, 0, 0, 0.1);
    background-color: $white;
    color: $c-title;
    @include lg {
      height: 0; } }
  &__inner {
    height: 100%;
    @include flex(flex-start, center);
    @include lg {
      padding-top: 10px;
      padding-bottom: 10px; } }
  &__logo {
    font-size: 0; }
  &__contacts {
    @include flex(space-around, center);
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 15px;
    @include lg {
      justify-content: flex-end; } }
  &__contact {
    &:not(.header__phone) {
      @include lg {
        display: none; } } }
  &__lang {
    text-align: right; }
  &__phone {
    @include xss {
      a {
        font-size: em(12);
 } } }        // display: none
  &__nav {
    width: 100%;
    @include lg {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      padding: 15px;
      overflow: auto;
      height: auto;
      background-color: $white;
      @include ready {
        @include tr(opacity .3s, visibility .3s); }
      @include hidden;
      @include vertical;
      &.is-active {
        @include visible; } } }
  &__nav-in {
    @include lg {
      display: inline-block;
      vertical-align: middle;
      font-size: $fz;
      @include sm {
        font-size: $fz-sm-px; } } }

  &__burger {
    display: none;
    margin-left: 15px;
    @include lg {
      display: block; }
    @include xs {
      margin-left: 5px; } }
  &__close {
    color: $c-title;
    display: none;
    @include lg {
      display: block; } } }

.header-contact {
  @include flex(flex-start, center);
  // font-weight: 300
  color: $c-title;
  &__icon {
    margin-right: 8px;
    color: $accent;
    font-size: 0;
    @include xss {
      margin-right: 4px; }
    .icon {
      font-size: $fz;
      @include sm {
        font-size: $fz-sm-px; } } }
  &__text {
    a {
      @include tr(color .3s);
      @include hover {
        color: $accent; } } }
  &__small {
    text-decoration: underline;
    color: $c-text;
    font-size: $fz-sm; } }


.lang {
  li {
    display: inline-block; }
  a {
    @include tr(color .3s);
    @include hover {
      color: $c-title; }
    &.is-active {
      color: $c-title; } } }

.burger {
  display: block;
  width: 28px;
  height: 28px;
  position: relative;
  span:after, span:before {
    content: "";
    position: absolute;
    left: 0; }
  span:after {
    top: 5px; }
  span:before {
    bottom: 5px; }
  span {
    display: block; }
  span, span:after, span:before {
    width: 100%;
    height: 2px;
    background-color: currentColor;
    backface-visibility: hidden;
    border-radius: 2px; } }
