.nav {
  font-weight: 700;
  ul {
    @include flex(space-between, center);
    @include lg {
      display: block; } }
  li {
    position: relative;
    @include hover {
      > ul {
        @include visible; } }
    @include lg {
      padding-top: 8px;
      padding-bottom: 8px; }
    > ul {
      position: absolute;
      display: block;
      z-index: 1;
      top: 100%;
      left: 0;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.19);
      background-color: $white;
      padding: 20px;
      color: $c-title;
      width: 430px;
      @include ready {
        @include tr(opacity .3s, visibility .3s, max-height .5s); }
      @include lgmin {
        @include hidden; }
      @include lg {
        position: static;
        width: auto;
        background-color: transparent;
        box-shadow: none;
        color: $white;
        padding-top: 0;
        padding-bottom: 0;
        font-size: .8em;
        max-height: 0;
        overflow: hidden; }
      &.is-open {
        @include visible; }
      li {
        padding-top: .5em;
        padding-bottom: .5em; }
      a {
        height: auto;
        display: inline;
        @include hover {
          text-decoration: none; } } }
    &:nth-child(4) ~ li {
      > ul {
        left: auto;
        right: 0; } } }

  a {
    @include flex(flex-start, center);
    height: $header-bottom-height;
    padding-right: 10px;
    @include tr(color .3s);
    @include lg {
      display: inline;
      height: auto;
      padding-right: 0; }
    @include hover {
      color: $accent;
 }      // text-decoration: underline
    &.is-active {
      color: $accent;
 } }      // text-decoration: underline

  &--footer {
    color: $c-title;
    font-size: $fz-sm;
    font-weight: 300;
    ul {
      display: block; }
    li {
      @include lg {
        padding-top: 0;
        padding-bottom: 0; }
      @include notlast {
        margin-bottom: 10px; } }
    a {
      display: inline;
      height: auto;
      @include hover {
        text-decoration: none;
        color: $c-text; }
      &.is-active {
        text-decoration: none;
        color: $c-text; } } }

  &--content {
    color: $blue;
    text-decoration: underline;
    font-size: $fz-sm;
    font-weight: 400;
    ul {
      flex-wrap: wrap;
      @include lg {
        display: flex; } }
    li {
      width: 50%;
      padding-right: 10px;
      margin-bottom: .5em;
      @include lg {
        padding-top: 0;
        padding-bottom: 0; } }
    a {
      display: inline;
      height: auto;
      padding-right: 0; } } }
