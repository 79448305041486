$fz:       16px;
$fz-sm-px: 14px;
$fz-xs-px: 12px;
$fz-sm:    em(14);
$fz-xs:    em(12);

$c-text: #353535;
$c-title: #353535;

$black: #000;
$white: #fff;

$gray: #757577;
$gray-light: #e2e2e2;
$gray-lightest: #fafafa;

$accent: #8dc63f;
$accent-dark: #78b02b;

$blue: #6ab4ff;

$header-top-height: 70px;
$header-top-height-lg: 55px;

$header-bottom-height: 75px;

$header-height: $header-top-height + $header-bottom-height;
$header-height-lg: $header-top-height-lg;

$radius: 25px;
$radius-sm: 15px;
