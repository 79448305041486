.form {
  text-align: center;
  &__title {
    margin-bottom: 10px; }
  &__subttl {
    margin-bottom: 30px;
    @include md {
      margin-bottom: 15px; } }
  &__field {
    @include notlast {
      margin-bottom: 15px; } }
  &__block-title {
    text-align: left;
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__block {
    @include notlast {
      margin-bottom: 25px;
      @include md {
        margin-bottom: 15px; } } }
  &__radios {
    @include flex(flex-start, center);
    .input {
      @include notlast {
        margin-right: 45px; } } }
  &__chekcboxes {
    .input {
      @include notlast {
        margin-bottom: 10px; } } } }
