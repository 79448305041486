.block {
  background-color: $white;
  border-radius: $radius-sm;
  padding: 30px 40px;
  box-shadow: 0.551px 1.923px 6px 0px rgba(0, 0, 0, 0.09);
  @include xl {
    padding: 20px 30px; }
  @include md {
    padding: 15px 20px; }
  &__title {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; } }
  &__text {
    @include notlast {
      margin-bottom: 25px;
      @include md {
        margin-bottom: 15px; } }
    .list, p {
      @include notlast {
        margin-bottom: 1em; } } }
  &__btn {
    text-align: center; }

  &--sm {
    padding: 5px 20px;
    @include xl {
      padding: 5px 20px; }
    @include md {
      padding: 5px 15px; } }
  &--gray {
    box-shadow: none;
    background-color: $gray-lightest; }
  &--lg {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.19);
    padding-top: 50px;
    padding-bottom: 50px;
    @include xl {
      padding-top: 40px;
      padding-bottom: 40px; }
    @include lg {
      padding-top: 30px;
      padding-bottom: 30px; } } }


.block-nav {
  $this: &;
  &__list {
    @include flex(space-between, center, row, wrap);
    @include xl {
      justify-content: flex-start; } }
  &__item {
    @include xl {
      width: 33.33333%; }
    @include lg {
      width: 50%; }
    @include xs {
      width: 100%; } }
  &__link {
    @include tr(color .3s);
    &.is-active {
      color: $accent; }
    @include hover {
      @extend #{$this}__link.is-active; } } }

